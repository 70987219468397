const allLanguages: Array<{ id: number; iso: string; name: string }> = [
  { id: 1, iso: 'eng', name: 'English' },
  { id: 2, iso: 'srp', name: 'Serbian' },
  { id: 3, iso: 'ceb', name: 'Cebuano' },
  { id: 4, iso: 'tgl', name: 'Tagalog' },
  { id: 5, iso: 'spa', name: 'Spanish' },
  { id: 6, iso: 'cmn', name: 'Mandarin Chinese' },
  { id: 7, iso: 'jpn', name: 'Japanese' },
  { id: 8, iso: 'kor', name: 'Korean' },
  { id: 9, iso: 'deu', name: 'German' },
  { id: 10, iso: 'fra', name: 'French' },
  { id: 11, iso: 'ita', name: 'Italian' },
  { id: 12, iso: 'rus', name: 'Russian' },
  { id: 13, iso: 'arb', name: 'Standard Arabic' },
  { id: 14, iso: 'ben', name: 'Bengali' },
  { id: 15, iso: 'hin', name: 'Hindi' },
  { id: 16, iso: 'ell', name: 'Greek' },
  { id: 17, iso: 'por', name: 'Portuguese' },
  { id: 18, iso: 'vie', name: 'Vietnamese' },
  { id: 19, iso: 'tur', name: 'Turkish' },
  { id: 20, iso: 'ind', name: 'Indonesian' },
  { id: 21, iso: 'pol', name: 'Polish' },
  { id: 22, iso: 'bos', name: 'Bosnian' },
  { id: 23, iso: 'hrv', name: 'Croatian' },
  { id: 24, iso: 'bul', name: 'Bulgarian' },
  { id: 25, iso: 'hun', name: 'Hungarian' },
  { id: 26, iso: 'fin', name: 'Finnish' },
  { id: 27, iso: 'ron', name: 'Romanian' },
  { id: 28, iso: 'jav', name: 'Javanese' },
  { id: 29, iso: 'tel', name: 'Telugu' },
  { id: 30, iso: 'mar', name: 'Marathi' },
  { id: 31, iso: 'tam', name: 'Tamil' },
  { id: 32, iso: 'pnb', name: 'Western Panjabi' },
  { id: 33, iso: 'guj', name: 'Gujarati' },
  { id: 34, iso: 'pes', name: 'Iranian Persian' },
  { id: 35, iso: 'bho', name: 'Bhojpuri' },
  { id: 36, iso: 'kan', name: 'Kannada' },
  { id: 37, iso: 'sun', name: 'Sundanese' },
  { id: 38, iso: 'mal', name: 'Malayalam' },
  { id: 39, iso: 'ory', name: 'Oriya' },
  { id: 40, iso: 'mya', name: 'Burmese' },
  { id: 41, iso: 'ukr', name: 'Ukrainian' },
  { id: 42, iso: 'pan', name: 'Panjabi' },
  { id: 43, iso: 'amh', name: 'Amharic' },
  { id: 44, iso: 'yor', name: 'Yoruba' },
  { id: 47, iso: 'tha', name: 'Thai' },
  { id: 48, iso: 'uzn', name: 'Northern Uzbek' },
  { id: 49, iso: 'nld', name: 'Dutch' },
  { id: 50, iso: 'sin', name: 'Sinhala' },
  { id: 51, iso: 'swh', name: 'Swahili' },
  { id: 52, iso: 'khm', name: 'Khmer' },
  { id: 53, iso: 'npi', name: 'Nepali' },
  { id: 54, iso: 'zul', name: 'Zulu' },
  { id: 55, iso: 'ces', name: 'Czech' },
  { id: 56, iso: 'urd', name: 'Urdu' },
  { id: 57, iso: 'run', name: 'Rundi' },
  { id: 58, iso: 'swe', name: 'Swedish' },
  { id: 59, iso: 'xho', name: 'Xhosa' },
  { id: 60, iso: 'prs', name: 'Dari' },
  { id: 61, iso: 'plt', name: 'Malagasy' },
  { id: 62, iso: 'hat', name: 'Haitian' },
  { id: 63, iso: 'afr', name: 'Afrikaans' },
  { id: 64, iso: 'kin', name: 'Kinyarwanda' },
  { id: 65, iso: 'som', name: 'Somali' },
  { id: 66, iso: 'azj', name: 'North Azerbaijani' },
  { id: 67, iso: 'pbt', name: 'Southern Pashto' },
  { id: 68, iso: 'dan', name: 'Danish' },
  { id: 69, iso: 'kaz', name: 'Kazakh' },
  { id: 70, iso: 'heb', name: 'Hebrew' },
  { id: 71, iso: 'slk', name: 'Slovak' },
  { id: 72, iso: 'gug', name: 'Paraguayan Guaraní' },
  { id: 73, iso: 'nor', name: 'Norwegian' },
  { id: 74, iso: 'nso', name: 'Pedi' },
  { id: 75, iso: 'kat', name: 'Georgian' },
  { id: 76, iso: 'tuk', name: 'Turkmen' },
  { id: 77, iso: 'tgk', name: 'Tajik' },
  { id: 78, iso: 'hye', name: 'Armenian' },
  { id: 79, iso: 'lao', name: 'Lao' },
  { id: 80, iso: 'lit', name: 'Lithuanian' },
  { id: 81, iso: 'kir', name: 'Kirghiz' },
  { id: 82, iso: 'khk', name: 'Halh Mongolian' },
  { id: 83, iso: 'tso', name: 'Tsonga' },
  { id: 84, iso: 'bel', name: 'Belarusian' },
  { id: 85, iso: 'slv', name: 'Slovenian' },
  { id: 86, iso: 'mkd', name: 'Macedonian' },
  { id: 87, iso: 'mlt', name: 'Maltese' },
  { id: 88, iso: 'isl', name: 'Icelandic' },
  { id: 89, iso: 'mai', name: 'Maithili' },
  { id: 90, iso: 'hau', name: 'Hausa' },
  { id: 91, iso: 'snd', name: 'Sindhi' },
  { id: 92, iso: 'ibo', name: 'Igbo' },
  { id: 93, iso: 'asm', name: 'Assamese' },
  { id: 94, iso: 'gaz', name: 'West Central Oromo' },
  { id: 95, iso: 'uig', name: 'Uighur' },
  { id: 96, iso: 'lua', name: 'Luba-Lulua' },
  { id: 97, iso: 'tir', name: 'Tigrinya' },
  { id: 98, iso: 'tat', name: 'Tatar' },
  { id: 99, iso: 'bem', name: 'Bemba' },
  { id: 100, iso: 'wuu', name: 'Wu Chinese' },
  { id: 101, iso: 'yue', name: 'Yue Chinese' },
  { id: 102, iso: 'cjy', name: 'Jinyu Chinese' },
  { id: 103, iso: 'hsn', name: 'Xiang Chinese' },
  { id: 104, iso: 'hak', name: 'Hakka Chinese' },
  { id: 105, iso: 'nan', name: 'Min Nan Chinese' },
  { id: 106, iso: 'gan', name: 'Gan Chinese' },
  { id: 107, iso: 'mnp', name: 'Min Bei Chinese' },
  { id: 108, iso: 'cdo', name: 'Min Dong Chinese' },
  { id: 130, iso: 'cat', name: 'Catalan' },
  { id: 131, iso: 'ckb', name: 'Central Kurdish' },
  { id: 132, iso: 'mvf', name: 'Peripheral Mongolian' },
  { id: 133, iso: 'kng', name: 'Koongo' },
  { id: 134, iso: 'uzs', name: 'Southern Uzbek' },
  { id: 135, iso: 'fuf', name: 'Pular' },
  { id: 136, iso: 'knn', name: 'Konkani' },
  { id: 137, iso: 'glg', name: 'Galician' },
  { id: 138, iso: 'lin', name: 'Lingala' },
  { id: 139, iso: 'aln', name: 'Gheg Albanian' },
  { id: 140, iso: 'mni', name: 'Manipuri' },
  { id: 141, iso: 'che', name: 'Chechen' },
  { id: 142, iso: 'toi', name: 'Tonga (Zambia)' },
  { id: 143, iso: 'aar', name: 'Afar' },
  { id: 144, iso: 'bod', name: 'Tibetan' },
  { id: 150, iso: 'arz', name: 'Egyptian Arabic' },
  { id: 151, iso: 'arq', name: 'Algerian Arabic' },
  { id: 152, iso: 'ary', name: 'Moroccan Arabic' },
  { id: 153, iso: 'apd', name: 'Sudanese Arabic' },
  { id: 154, iso: 'acm', name: 'Mesopotamian Arabic' },
  { id: 155, iso: 'aeb', name: 'Tunisian Arabic' },
  { id: 156, iso: 'apc', name: 'North Levantine Arabic' },
  { id: 157, iso: 'ars', name: 'Najdi Arabic' },
  { id: 158, iso: 'ayl', name: 'Libyan Arabic' },
  { id: 159, iso: 'ajp', name: 'South Levantine Arabic' },
  { id: 170, iso: 'hil', name: 'Hiligaynon' },
  { id: 171, iso: 'ilo', name: 'Iloko' },
  { id: 172, iso: 'war', name: 'Waray (Philippines)' },
  { id: 173, iso: 'bcl', name: 'Central Bikol' },
  { id: 180, iso: 'gsw', name: 'Swiss German' },
  { id: 203, iso: 'akh', name: 'Angal Heneng' },
  { id: 207, iso: 'akl', name: 'Aklanon' },
  { id: 210, iso: 'akp', name: 'Siwu' },
  { id: 223, iso: 'ald', name: 'Alladian' },
  { id: 234, iso: 'alp', name: 'Alune' },
  { id: 237, iso: 'als', name: 'Tosk Albanian' },
  { id: 238, iso: 'alt', name: 'Southern Altai' },
  { id: 239, iso: 'alu', name: '\'Are\'are' },
  { id: 240, iso: 'alw', name: 'Alaba-K’abeena' },
  { id: 243, iso: 'alz', name: 'Alur' },
  { id: 251, iso: 'ami', name: 'Amis' },
  { id: 263, iso: 'amu', name: 'Guerrero Amuzgo' },
  { id: 271, iso: 'anc', name: 'Ngas' },
  { id: 279, iso: 'ank', name: 'Goemai' },
  { id: 281, iso: 'anm', name: 'Anal' },
  { id: 282, iso: 'ann', name: 'Obolo' },
  { id: 284, iso: 'anp', name: 'Angika' },
  { id: 286, iso: 'anr', name: 'Andh' },
  { id: 289, iso: 'anu', name: 'Anuak' },
  { id: 290, iso: 'anv', name: 'Denya' },
  { id: 291, iso: 'anw', name: 'Anaang' },
  { id: 293, iso: 'any', name: 'Anyin' },
  { id: 299, iso: 'aoe', name: 'Angal Enen' },
  { id: 304, iso: 'aoj', name: 'Mufian' },
  { id: 314, iso: 'aoz', name: 'Uab Meto' },
  { id: 315, iso: 'apb', name: 'Sa\'a' },
  { id: 360, iso: 'arn', name: 'Mapudungun' },
  { id: 372, iso: 'asa', name: 'Asu (Tanzania)' },
  { id: 378, iso: 'asg', name: 'Cishingini' },
  { id: 386, iso: 'aso', name: 'Dano' },
  { id: 391, iso: 'ast', name: 'Asturian' },
  { id: 399, iso: 'atb', name: 'Zaiwa' },
  { id: 401, iso: 'atd', name: 'Ata Manobo' },
  { id: 403, iso: 'atg', name: 'Ivbie North-Okpela-Arhe' },
  { id: 404, iso: 'ati', name: 'Attié' },
  { id: 412, iso: 'atq', name: 'Aralle-Tabulahan' },
  { id: 428, iso: 'auh', name: 'Aushi' },
  { id: 443, iso: 'auy', name: 'Awiyaana' },
  { id: 445, iso: 'ava', name: 'Avaric' },
  { id: 449, iso: 'avi', name: 'Avikam' },
  { id: 451, iso: 'avl', name: 'Eastern Egyptian Bedawi Arabic' },
  { id: 453, iso: 'avn', name: 'Avatime' },
  { id: 457, iso: 'avu', name: 'Avokaya' },
  { id: 459, iso: 'awa', name: 'Awadhi' },
  { id: 468, iso: 'awn', name: 'Awngi' },
  { id: 481, iso: 'axk', name: 'Yaka (Central African Republic)' },
  { id: 486, iso: 'ayb', name: 'Ayizo Gbe' },
  { id: 487, iso: 'ayc', name: 'Southern Aymara' },
  { id: 491, iso: 'ayh', name: 'Hadrami Arabic' },
  { id: 496, iso: 'ayn', name: 'Sanaani Arabic' },
  { id: 498, iso: 'ayp', name: 'North Mesopotamian Arabic' },
  { id: 500, iso: 'ayr', name: 'Central Aymara' },
  { id: 505, iso: 'ayz', name: 'Mai Brat' },
  { id: 507, iso: 'azb', name: 'South Azerbaijani' },
  { id: 514, iso: 'azo', name: 'Awing' },
  { id: 516, iso: 'azz', name: 'Highland Puebla Nahuatl' },
  { id: 523, iso: 'bah', name: 'Bahamas Creole English' },
  { id: 525, iso: 'bak', name: 'Bashkir' },
  { id: 527, iso: 'bam', name: 'Bambara' },
  { id: 528, iso: 'ban', name: 'Balinese' },
  { id: 530, iso: 'bap', name: 'Bantawa' },
  { id: 531, iso: 'bar', name: 'Bavarian' },
  { id: 532, iso: 'bas', name: 'Basa (Cameroon)' },
  { id: 534, iso: 'bav', name: 'Vengo' },
  { id: 536, iso: 'bax', name: 'Bamun' },
  { id: 538, iso: 'bba', name: 'Baatonum' },
  { id: 540, iso: 'bbc', name: 'Batak Toba' },
  { id: 547, iso: 'bbj', name: 'Ghomálá\'' },
  { id: 548, iso: 'bbk', name: 'Babanki' },
  { id: 564, iso: 'bca', name: 'Central Bai' },
  { id: 566, iso: 'bcc', name: 'Southern Balochi' },
  { id: 572, iso: 'bci', name: 'Baoulé' },
  { id: 580, iso: 'bcq', name: 'Bench' },
  { id: 586, iso: 'bcw', name: 'Bana' },
  { id: 587, iso: 'bcy', name: 'Bacama' },
  { id: 593, iso: 'bde', name: 'Bade' },
  { id: 596, iso: 'bdh', name: 'Baka (Sudan)' },
  { id: 597, iso: 'bdi', name: 'Burun' },
  { id: 600, iso: 'bdl', name: 'Indonesian Bajau' },
  { id: 605, iso: 'bdq', name: 'Bahnar' },
  { id: 608, iso: 'bdt', name: 'Bokoto' },
  { id: 609, iso: 'bdu', name: 'Oroko' },
  { id: 610, iso: 'bdv', name: 'Bodo Parja' },
  { id: 620, iso: 'bef', name: 'Benabena' },
  { id: 622, iso: 'beh', name: 'Biali' },
  { id: 624, iso: 'bej', name: 'Beja' },
  { id: 631, iso: 'beq', name: 'Beembe' },
  { id: 633, iso: 'bet', name: 'Guiberoua Béte' },
  { id: 635, iso: 'bev', name: 'Daloa Bété' },
  { id: 636, iso: 'bew', name: 'Betawi' },
  { id: 637, iso: 'bex', name: 'Jur Modo' },
  { id: 639, iso: 'bez', name: 'Bena (Tanzania)' },
  { id: 640, iso: 'bfa', name: 'Bari' },
  { id: 641, iso: 'bfb', name: 'Pauri Bareli' },
  { id: 643, iso: 'bfd', name: 'Bafut' },
  { id: 654, iso: 'bfo', name: 'Malba Birifor' },
  { id: 656, iso: 'bfq', name: 'Badaga' },
  { id: 658, iso: 'bfs', name: 'Southern Bai' },
  { id: 659, iso: 'bft', name: 'Balti' },
  { id: 663, iso: 'bfy', name: 'Bagheli' },
  { id: 664, iso: 'bfz', name: 'Mahasu Pahari' },
  { id: 667, iso: 'bgc', name: 'Haryanvi' },
  { id: 668, iso: 'bgd', name: 'Rathwi Bareli' },
  { id: 677, iso: 'bgn', name: 'Western Balochi' },
  { id: 679, iso: 'bgp', name: 'Eastern Balochi' },
  { id: 680, iso: 'bgq', name: 'Bagri' },
  { id: 682, iso: 'bgs', name: 'Tagabawa' },
  { id: 686, iso: 'bgw', name: 'Bhatri' },
  { id: 687, iso: 'bgx', name: 'Balkan Gagauz Turkish' },
  { id: 689, iso: 'bgz', name: 'Banggai' },
  { id: 690, iso: 'bha', name: 'Bharia' },
  { id: 691, iso: 'bhb', name: 'Bhili' },
  { id: 698, iso: 'bhi', name: 'Bhilali' },
  { id: 704, iso: 'bhp', name: 'Bima' },
  { id: 705, iso: 'bhq', name: 'Tukang Besi South' },
  { id: 706, iso: 'bhr', name: 'Bara Malagasy' },
  { id: 708, iso: 'bht', name: 'Bhattiyali' },
  { id: 716, iso: 'bib', name: 'Bissa' },
  { id: 725, iso: 'bim', name: 'Bimoba' },
  { id: 726, iso: 'bin', name: 'Bini' },
  { id: 731, iso: 'bis', name: 'Bislama' },
  { id: 733, iso: 'biu', name: 'Biete' },
  { id: 734, iso: 'biv', name: 'Southern Birifor' },
  { id: 739, iso: 'bja', name: 'Budza' },
  { id: 746, iso: 'bji', name: 'Burji' },
  { id: 747, iso: 'bjj', name: 'Kanauji' },
  { id: 751, iso: 'bjn', name: 'Banjar' },
  { id: 752, iso: 'bjo', name: 'Mid-Southern Banda' },
  { id: 755, iso: 'bjs', name: 'Bajan' },
  { id: 756, iso: 'bjt', name: 'Balanta-Ganja' },
  { id: 765, iso: 'bkd', name: 'Binukid' },
  { id: 773, iso: 'bkm', name: 'Kom (Cameroon)' },
  { id: 778, iso: 'bkr', name: 'Bakumpai' },
  { id: 782, iso: 'bkv', name: 'Bekwarra' },
  { id: 785, iso: 'bky', name: 'Bokyi' },
  { id: 791, iso: 'ble', name: 'Balanta-Kentohe' },
  { id: 794, iso: 'blh', name: 'Kuwaa' },
  { id: 795, iso: 'bli', name: 'Bolia' },
  { id: 797, iso: 'blk', name: 'Pa\'o Karen' },
  { id: 799, iso: 'blm', name: 'Beli (Sudan)' },
  { id: 800, iso: 'bln', name: 'Southern Catanduanes Bikol' },
  { id: 806, iso: 'blt', name: 'Tai Dam' },
  { id: 808, iso: 'blw', name: 'Balangao' },
  { id: 811, iso: 'blz', name: 'Balantak' },
  { id: 813, iso: 'bmb', name: 'Bembe' },
  { id: 820, iso: 'bmi', name: 'Bagirmi' },
  { id: 824, iso: 'bmm', name: 'Northern Betsimisaraka Malagasy' },
  { id: 826, iso: 'bmo', name: 'Bambalang' },
  { id: 828, iso: 'bmq', name: 'Bomu' },
  { id: 833, iso: 'bmv', name: 'Bum' },
  { id: 850, iso: 'bnn', name: 'Bunun' },
  { id: 851, iso: 'bno', name: 'Bantoanon' },
  { id: 852, iso: 'bnp', name: 'Bola' },
  { id: 855, iso: 'bns', name: 'Bundeli' },
  { id: 859, iso: 'bnx', name: 'Bangubangu' },
  { id: 872, iso: 'bol', name: 'Bole' },
  { id: 873, iso: 'bom', name: 'Berom' },
  { id: 880, iso: 'bot', name: 'Bongo' },
  { id: 882, iso: 'bov', name: 'Tuwuli' },
  { id: 884, iso: 'box', name: 'Buamu' },
  { id: 886, iso: 'boz', name: 'Tiéyaxo Bozo' },
  { id: 889, iso: 'bpd', name: 'Banda-Banda' },
  { id: 893, iso: 'bpj', name: 'Binji' },
  { id: 901, iso: 'bpr', name: 'Koronadal Blaan' },
  { id: 902, iso: 'bps', name: 'Sarangani Blaan' },
  { id: 908, iso: 'bpy', name: 'Bishnupriya' },
  { id: 912, iso: 'bqc', name: 'Boko (Benin)' },
  { id: 917, iso: 'bqi', name: 'Bakhtiari' },
  { id: 918, iso: 'bqj', name: 'Bandial' },
  { id: 924, iso: 'bqp', name: 'Busa' },
  { id: 930, iso: 'bqv', name: 'Koro Wachi' },
  { id: 935, iso: 'bra', name: 'Braj' },
  { id: 936, iso: 'brb', name: 'Lave' },
  { id: 939, iso: 'bre', name: 'Breton' },
  { id: 940, iso: 'brf', name: 'Bera' },
  { id: 942, iso: 'brh', name: 'Brahui' },
  { id: 955, iso: 'bru', name: 'Eastern Bru' },
  { id: 958, iso: 'brx', name: 'Bodo (India)' },
  { id: 962, iso: 'bsb', name: 'Brunei Bisaya' },
  { id: 963, iso: 'bsc', name: 'Bassari' },
  { id: 976, iso: 'bsq', name: 'Bassa' },
  { id: 978, iso: 'bss', name: 'Akoose' },
  { id: 979, iso: 'bst', name: 'Basketo' },
  { id: 985, iso: 'bta', name: 'Bata' },
  { id: 987, iso: 'btd', name: 'Batak Dairi' },
  { id: 990, iso: 'btg', name: 'Gagnoa Bété' },
  { id: 995, iso: 'btm', name: 'Batak Mandailing' },
  { id: 997, iso: 'bto', name: 'Rinconada Bikol' },
  { id: 1001, iso: 'bts', name: 'Batak Simalungun' },
  { id: 1002, iso: 'btt', name: 'Bete-Bendi' },
  { id: 1006, iso: 'btx', name: 'Batak Karo' },
  { id: 1008, iso: 'btz', name: 'Batak Alas-Kluet' },
  { id: 1012, iso: 'bud', name: 'Ntcham' },
  { id: 1014, iso: 'buf', name: 'Bushoong' },
  { id: 1015, iso: 'bug', name: 'Buginese' },
  { id: 1019, iso: 'buk', name: 'Bugawac' },
  { id: 1021, iso: 'bum', name: 'Bulu (Cameroon)' },
  { id: 1022, iso: 'bun', name: 'Sherbro' },
  { id: 1023, iso: 'buo', name: 'Terei' },
  { id: 1026, iso: 'bus', name: 'Bokobaru' },
  { id: 1028, iso: 'buu', name: 'Budu' },
  { id: 1042, iso: 'bvi', name: 'Belanda Viri' },
  { id: 1046, iso: 'bvm', name: 'Bamunka' },
  { id: 1068, iso: 'bwj', name: 'Láá Láá Bwamu' },
  { id: 1073, iso: 'bwo', name: 'Boro (Ethiopia)' },
  { id: 1075, iso: 'bwq', name: 'Southern Bobo Madaré' },
  { id: 1076, iso: 'bwr', name: 'Bura-Pabir' },
  { id: 1079, iso: 'bwu', name: 'Buli (Ghana)' },
  { id: 1080, iso: 'bww', name: 'Bwa' },
  { id: 1081, iso: 'bwx', name: 'Bu-Nao Bunu' },
  { id: 1094, iso: 'bxk', name: 'Bukusu' },
  { id: 1101, iso: 'bxr', name: 'Russia Buriat' },
  { id: 1121, iso: 'byn', name: 'Bilin' },
  { id: 1122, iso: 'byo', name: 'Biyo' },
  { id: 1128, iso: 'byv', name: 'Medumba' },
  { id: 1133, iso: 'bza', name: 'Bandi' },
  { id: 1135, iso: 'bzc', name: 'Southern Betsimisaraka Malagasy' },
  { id: 1136, iso: 'bzd', name: 'Bribri' },
  { id: 1137, iso: 'bze', name: 'Jenaama Bozo' },
  { id: 1138, iso: 'bzf', name: 'Boikin' },
  { id: 1140, iso: 'bzh', name: 'Mapos Buang' },
  { id: 1142, iso: 'bzj', name: 'Belize Kriol English' },
  { id: 1143, iso: 'bzk', name: 'Nicaragua Creole English' },
  { id: 1155, iso: 'bzw', name: 'Basa (Nigeria)' },
  { id: 1159, iso: 'caa', name: 'Chortí' },
  { id: 1160, iso: 'cab', name: 'Garifuna' },
  { id: 1161, iso: 'cac', name: 'Chuj' },
  { id: 1165, iso: 'cag', name: 'Nivaclé' },
  { id: 1168, iso: 'cak', name: 'Kaqchikel' },
  { id: 1174, iso: 'caq', name: 'Car Nicobarese' },
  { id: 1191, iso: 'cbk', name: 'Chavacano' },
  { id: 1205, iso: 'cce', name: 'Chopi' },
  { id: 1206, iso: 'ccg', name: 'Samba Daka' },
  { id: 1207, iso: 'cch', name: 'Atsam' },
  { id: 1212, iso: 'ccp', name: 'Chakma' },
  { id: 1214, iso: 'cda', name: 'Choni' },
  { id: 1217, iso: 'cdg', name: 'Chamari' },
  { id: 1218, iso: 'cdh', name: 'Chambeali' },
  { id: 1219, iso: 'cdi', name: 'Chodri' },
  { id: 1220, iso: 'cdj', name: 'Churahi' },
  { id: 1235, iso: 'cfa', name: 'Dijim-Bwilim' },
  { id: 1238, iso: 'cfm', name: 'Falam Chin' },
  { id: 1240, iso: 'cgc', name: 'Kagayanen' },
  { id: 1241, iso: 'cgg', name: 'Chiga' },
  { id: 1243, iso: 'cha', name: 'Chamorro' },
  { id: 1251, iso: 'chj', name: 'Ojitlán Chinantec' },
  { id: 1252, iso: 'chk', name: 'Chuukese' },
  { id: 1262, iso: 'chv', name: 'Chuvash' },
  { id: 1263, iso: 'chw', name: 'Chuwabu' },
  { id: 1280, iso: 'cja', name: 'Western Cham' },
  { id: 1284, iso: 'cjk', name: 'Chokwe' },
  { id: 1290, iso: 'cjv', name: 'Chuave' },
  { id: 1294, iso: 'ckl', name: 'Cibak' },
  { id: 1296, iso: 'cko', name: 'Anufo' },
  { id: 1306, iso: 'cla', name: 'Ron' },
  { id: 1308, iso: 'cld', name: 'Chaldean Neo-Aramaic' },
  { id: 1318, iso: 'clu', name: 'Caluyanun' },
  { id: 1322, iso: 'cme', name: 'Cerma' },
  { id: 1328, iso: 'cmo', name: 'Central Mnong' },
  { id: 1336, iso: 'cnh', name: 'Haka Chin' },
  { id: 1337, iso: 'cni', name: 'Asháninka' },
  { id: 1338, iso: 'cnk', name: 'Khumi Chin' },
  { id: 1339, iso: 'cnl', name: 'Lalana Chinantec' },
  { id: 1344, iso: 'cnw', name: 'Ngawn Chin' },
  { id: 1353, iso: 'coh', name: 'Chonyi-Dzihana-Kauma' },
  { id: 1363, iso: 'cos', name: 'Corsican' },
  { id: 1376, iso: 'cpn', name: 'Cherepon' },
  { id: 1378, iso: 'cps', name: 'Capiznon' },
  { id: 1380, iso: 'cpx', name: 'Pu-Xian Chinese' },
  { id: 1382, iso: 'cqd', name: 'Chuanqiandian Cluster Miao' },
  { id: 1391, iso: 'crh', name: 'Crimean Tatar' },
  { id: 1401, iso: 'crs', name: 'Seselwa Creole French' },
  { id: 1415, iso: 'csh', name: 'Asho Chin' },
  { id: 1418, iso: 'csk', name: 'Jola-Kasa' },
  { id: 1429, iso: 'csy', name: 'Siyin Chin' },
  { id: 1433, iso: 'ctd', name: 'Tedim Chin' },
  { id: 1435, iso: 'ctg', name: 'Chittagonian' },
  { id: 1441, iso: 'ctp', name: 'Western Highland Chatino' },
  { id: 1444, iso: 'ctu', name: 'Chol' },
  { id: 1453, iso: 'cuk', name: 'San Blas Kuna' },
  { id: 1475, iso: 'cym', name: 'Welsh' },
  { id: 1476, iso: 'cyo', name: 'Cuyonon' },
  { id: 1477, iso: 'czh', name: 'Huizhou Chinese' },
  { id: 1480, iso: 'czo', name: 'Min Zhong Chinese' },
  { id: 1481, iso: 'czt', name: 'Zotung Chin' },
  { id: 1482, iso: 'daa', name: 'Dangaléat' },
  { id: 1486, iso: 'dag', name: 'Dagbani' },
  { id: 1494, iso: 'dao', name: 'Daai Chin' },
  { id: 1495, iso: 'daq', name: 'Dandami Maria' },
  { id: 1496, iso: 'dar', name: 'Dargwa' },
  { id: 1499, iso: 'dav', name: 'Taita' },
  { id: 1500, iso: 'daw', name: 'Davawenyo' },
  { id: 1516, iso: 'dbq', name: 'Daba' },
  { id: 1523, iso: 'dcc', name: 'Deccan' },
  { id: 1531, iso: 'ddn', name: 'Dendi (Benin)' },
  { id: 1534, iso: 'dds', name: 'Donno So Dogon' },
  { id: 1549, iso: 'der', name: 'Deori' },
  { id: 1554, iso: 'dga', name: 'Southern Dagaare' },
  { id: 1561, iso: 'dgi', name: 'Northern Dagara' },
  { id: 1563, iso: 'dgl', name: 'Andaandi' },
  { id: 1565, iso: 'dgo', name: 'Dogri (individual language)' },
  { id: 1573, iso: 'dhd', name: 'Dhundari' },
  { id: 1577, iso: 'dhm', name: 'Zemba' },
  { id: 1578, iso: 'dhn', name: 'Dhanki' },
  { id: 1579, iso: 'dho', name: 'Dhodia' },
  { id: 1583, iso: 'dhv', name: 'Dehu' },
  { id: 1587, iso: 'dib', name: 'South Central Dinka' },
  { id: 1589, iso: 'did', name: 'Didinga' },
  { id: 1591, iso: 'dig', name: 'Digo' },
  { id: 1595, iso: 'dik', name: 'Southwestern Dinka' },
  { id: 1599, iso: 'dio', name: 'Dibo' },
  { id: 1600, iso: 'dip', name: 'Northeastern Dinka' },
  { id: 1601, iso: 'diq', name: 'Dimli (individual language)' },
  { id: 1603, iso: 'dis', name: 'Dimasa' },
  { id: 1605, iso: 'diu', name: 'Diriku' },
  { id: 1606, iso: 'div', name: 'Dhivehi' },
  { id: 1610, iso: 'diz', name: 'Ding' },
  { id: 1615, iso: 'dje', name: 'Zarma' },
  { id: 1619, iso: 'djk', name: 'Eastern Maroon Creole' },
  { id: 1620, iso: 'djm', name: 'Jamsay Dogon' },
  { id: 1629, iso: 'dks', name: 'Southeastern Dinka' },
  { id: 1630, iso: 'dkx', name: 'Mazagway' },
  { id: 1652, iso: 'dna', name: 'Upper Grand Valley Dani' },
  { id: 1654, iso: 'dne', name: 'Ndendeule' },
  { id: 1656, iso: 'dni', name: 'Lower Grand Valley Dani' },
  { id: 1657, iso: 'dnj', name: 'Dan' },
  { id: 1661, iso: 'dnt', name: 'Mid Grand Valley Dani' },
  { id: 1663, iso: 'dnv', name: 'Danu' },
  { id: 1664, iso: 'dnw', name: 'Western Dani' },
  { id: 1666, iso: 'doa', name: 'Dom' },
  { id: 1667, iso: 'dob', name: 'Dobu' },
  { id: 1668, iso: 'doc', name: 'Northern Dong' },
  { id: 1677, iso: 'dop', name: 'Lukpa' },
  { id: 1683, iso: 'dow', name: 'Doyayo' },
  { id: 1699, iso: 'drs', name: 'Gedeo' },
  { id: 1701, iso: 'dru', name: 'Rukai' },
  { id: 1705, iso: 'dsh', name: 'Daasanach' },
  { id: 1709, iso: 'dso', name: 'Desiya' },
  { id: 1710, iso: 'dsq', name: 'Tadaksahak' },
  { id: 1716, iso: 'dtk', name: 'Tene Kan Dogon' },
  { id: 1717, iso: 'dtm', name: 'Tomo Kan Dogon' },
  { id: 1719, iso: 'dtp', name: 'Central Dusun' },
  { id: 1721, iso: 'dts', name: 'Toro So Dogon' },
  { id: 1724, iso: 'dty', name: 'Dotyali' },
  { id: 1725, iso: 'dua', name: 'Duala' },
  { id: 1726, iso: 'dub', name: 'Dubli' },
  { id: 1727, iso: 'duc', name: 'Duna' },
  { id: 1728, iso: 'dud', name: 'Hun-Saare' },
  { id: 1731, iso: 'dug', name: 'Duruma' },
  { id: 1732, iso: 'duh', name: 'Dungra Bhil' },
  { id: 1742, iso: 'dur', name: 'Dii' },
  { id: 1752, iso: 'dwr', name: 'Dawro' },
  { id: 1759, iso: 'dyi', name: 'Djimini Senoufo' },
  { id: 1762, iso: 'dyo', name: 'Jola-Fonyi' },
  { id: 1763, iso: 'dyu', name: 'Dyula' },
  { id: 1768, iso: 'dzg', name: 'Dazaga' },
  { id: 1771, iso: 'dzo', name: 'Dzongkha' },
  { id: 1776, iso: 'ebr', name: 'Ebrié' },
  { id: 1777, iso: 'ebu', name: 'Embu' },
  { id: 1784, iso: 'efi', name: 'Efik' },
  { id: 1787, iso: 'ego', name: 'Eggon' },
  { id: 1796, iso: 'eke', name: 'Ekit' },
  { id: 1797, iso: 'ekg', name: 'Ekari' },
  { id: 1799, iso: 'ekk', name: 'Standard Estonian' },
  { id: 1811, iso: 'elm', name: 'Eleme' },
  { id: 1815, iso: 'ema', name: 'Emai-Iuleha-Ora' },
  { id: 1816, iso: 'emb', name: 'Embaloh' },
  { id: 1820, iso: 'emk', name: 'Eastern Maninkakan' },
  { id: 1823, iso: 'emp', name: 'Northern Emberá' },
  { id: 1825, iso: 'emu', name: 'Eastern Muria' },
  { id: 1830, iso: 'enb', name: 'Markweeta' },
  { id: 1832, iso: 'end', name: 'Ende' },
  { id: 1840, iso: 'enq', name: 'Enga' },
  { id: 1878, iso: 'ets', name: 'Yekhee' },
  { id: 1880, iso: 'etu', name: 'Ejagham' },
  { id: 1883, iso: 'eus', name: 'Basque' },
  { id: 1887, iso: 'ewe', name: 'Ewe' },
  { id: 1888, iso: 'ewo', name: 'Ewondo' },
  { id: 1889, iso: 'ext', name: 'Extremaduran' },
  { id: 1891, iso: 'eyo', name: 'Keiyo' },
  { id: 1892, iso: 'eza', name: 'Ezaa' },
  { id: 1903, iso: 'fal', name: 'South Fali' },
  { id: 1905, iso: 'fan', name: 'Fang (Equatorial Guinea)' },
  { id: 1906, iso: 'fao', name: 'Faroese' },
  { id: 1913, iso: 'fay', name: 'Southwestern Fars' },
  { id: 1915, iso: 'fbl', name: 'West Albay Bikol' },
  { id: 1919, iso: 'ffm', name: 'Maasina Fulfulde' },
  { id: 1921, iso: 'fia', name: 'Nobiin' },
  { id: 1923, iso: 'fij', name: 'Fijian' },
  { id: 1926, iso: 'fip', name: 'Fipa' },
  { id: 1928, iso: 'fit', name: 'Tornedalen Finnish' },
  { id: 1937, iso: 'flr', name: 'Fuliiru' },
  { id: 1939, iso: 'fmp', name: 'Fe\'fe\'' },
  { id: 1940, iso: 'fmu', name: 'Far Western Muria' },
  { id: 1946, iso: 'fon', name: 'Fon' },
  { id: 1947, iso: 'for', name: 'Fore' },
  { id: 1962, iso: 'fry', name: 'Western Frisian' },
  { id: 1966, iso: 'fub', name: 'Adamawa Fulfulde' },
  { id: 1967, iso: 'fuc', name: 'Pulaar' },
  { id: 1969, iso: 'fue', name: 'Borgu Fulfulde' },
  { id: 1971, iso: 'fuh', name: 'Western Niger Fulfulde' },
  { id: 1977, iso: 'fuq', name: 'Central-Eastern Niger Fulfulde' },
  { id: 1978, iso: 'fur', name: 'Friulian' },
  { id: 1981, iso: 'fuv', name: 'Nigerian Fulfulde' },
  { id: 1982, iso: 'fuy', name: 'Fuyug' },
  { id: 1983, iso: 'fvr', name: 'Fur' },
  { id: 1986, iso: 'gaa', name: 'Ga' },
  { id: 1987, iso: 'gab', name: 'Gabri' },
  { id: 1992, iso: 'gag', name: 'Gagauz' },
  { id: 1995, iso: 'gaj', name: 'Gadsup' },
  { id: 2004, iso: 'gas', name: 'Adiwasi Garasia' },
  { id: 2008, iso: 'gax', name: 'Borana-Arsi-Guji Oromo' },
  { id: 2009, iso: 'gay', name: 'Gayo' },
  { id: 2018, iso: 'gbi', name: 'Galela' },
  { id: 2020, iso: 'gbk', name: 'Gaddi' },
  { id: 2021, iso: 'gbl', name: 'Gamit' },
  { id: 2022, iso: 'gbm', name: 'Garhwali' },
  { id: 2024, iso: 'gbo', name: 'Northern Grebo' },
  { id: 2025, iso: 'gbp', name: 'Gbaya-Bossangoa' },
  { id: 2027, iso: 'gbr', name: 'Gbagyi' },
  { id: 2030, iso: 'gbv', name: 'Gbanu' },
  { id: 2033, iso: 'gby', name: 'Gbari' },
  { id: 2038, iso: 'gcf', name: 'Guadeloupean Creole French' },
  { id: 2047, iso: 'gde', name: 'Gude' },
  { id: 2054, iso: 'gdl', name: 'Dirasha' },
  { id: 2063, iso: 'gdx', name: 'Godwari' },
  { id: 2071, iso: 'gej', name: 'Gen' },
  { id: 2073, iso: 'gel', name: 'ut-Ma\'in' },
  { id: 2077, iso: 'gew', name: 'Gera' },
  { id: 2091, iso: 'ggn', name: 'Eastern Gurung' },
  { id: 2092, iso: 'ggo', name: 'Southern Gondi' },
  { id: 2095, iso: 'ggw', name: 'Gogodala' },
  { id: 2105, iso: 'ghs', name: 'Guhu-Samane' },
  { id: 2110, iso: 'gid', name: 'Gidar' },
  { id: 2113, iso: 'gil', name: 'Gilbertese' },
  { id: 2114, iso: 'gim', name: 'Gimi (Eastern Highlands)' },
  { id: 2125, iso: 'giz', name: 'South Giziga' },
  { id: 2127, iso: 'gjk', name: 'Kachi Koli' },
  { id: 2129, iso: 'gjn', name: 'Gonja' },
  { id: 2130, iso: 'gju', name: 'Gujari' },
  { id: 2133, iso: 'gkn', name: 'Gokana' },
  { id: 2135, iso: 'gkp', name: 'Guinea Kpelle' },
  { id: 2136, iso: 'gla', name: 'Scottish Gaelic' },
  { id: 2139, iso: 'gle', name: 'Irish' },
  { id: 2144, iso: 'glk', name: 'Gilaki' },
  { id: 2161, iso: 'gmv', name: 'Gamo' },
  { id: 2164, iso: 'gmz', name: 'Mgbolizhia' },
  { id: 2166, iso: 'gnb', name: 'Gangte' },
  { id: 2168, iso: 'gnd', name: 'Zulgo-Gemzek' },
  { id: 2170, iso: 'gng', name: 'Ngangam' },
  { id: 2177, iso: 'gno', name: 'Northern Gondi' },
  { id: 2184, iso: 'goa', name: 'Guro' },
  { id: 2187, iso: 'god', name: 'Godié' },
  { id: 2189, iso: 'gof', name: 'Gofa' },
  { id: 2190, iso: 'gog', name: 'Gogo' },
  { id: 2195, iso: 'gol', name: 'Gola' },
  { id: 2196, iso: 'gom', name: 'Goan Konkani' },
  { id: 2201, iso: 'gor', name: 'Gorontalo' },
  { id: 2202, iso: 'gos', name: 'Gronings' },
  { id: 2210, iso: 'gpe', name: 'Ghanaian Pidgin English' },
  { id: 2212, iso: 'gqa', name: 'Ga\'anda' },
  { id: 2217, iso: 'gra', name: 'Rajput Garasia' },
  { id: 2223, iso: 'gri', name: 'Ghari' },
  { id: 2231, iso: 'grt', name: 'Garo' },
  { id: 2232, iso: 'gru', name: 'Kistane' },
  { id: 2243, iso: 'gso', name: 'Southwest Gbaya' },
  { id: 2251, iso: 'gub', name: 'Guajajára' },
  { id: 2252, iso: 'guc', name: 'Wayuu' },
  { id: 2253, iso: 'gud', name: 'Yocoboué Dida' },
  { id: 2258, iso: 'gui', name: 'Eastern Bolivian Guaraní' },
  { id: 2260, iso: 'guk', name: 'Gumuz' },
  { id: 2262, iso: 'gum', name: 'Guambiano' },
  { id: 2267, iso: 'gur', name: 'Farefare' },
  { id: 2270, iso: 'guu', name: 'Yanomamö' },
  { id: 2272, iso: 'guw', name: 'Gun' },
  { id: 2273, iso: 'gux', name: 'Gourmanchéma' },
  { id: 2274, iso: 'guz', name: 'Gusii' },
  { id: 2278, iso: 'gvf', name: 'Golin' },
  { id: 2280, iso: 'gvl', name: 'Gulay' },
  { id: 2285, iso: 'gvr', name: 'Western Gurung' },
  { id: 2290, iso: 'gwc', name: 'Kalami' },
  { id: 2299, iso: 'gwr', name: 'Gwere' },
  { id: 2304, iso: 'gxx', name: 'Wè Southern' },
  { id: 2305, iso: 'gya', name: 'Northwest Gbaya' },
  { id: 2313, iso: 'gym', name: 'Ngäbere' },
  { id: 2314, iso: 'gyn', name: 'Guyanese Creole English' },
  { id: 2323, iso: 'had', name: 'Hatam' },
  { id: 2324, iso: 'hae', name: 'Eastern Oromo' },
  { id: 2329, iso: 'haj', name: 'Hajong' },
  { id: 2333, iso: 'han', name: 'Hangaza' },
  { id: 2336, iso: 'haq', name: 'Ha' },
  { id: 2341, iso: 'hav', name: 'Havu' },
  { id: 2344, iso: 'hay', name: 'Haya' },
  { id: 2345, iso: 'haz', name: 'Hazaragi' },
  { id: 2347, iso: 'hbb', name: 'Huba' },
  { id: 2353, iso: 'hch', name: 'Huichol' },
  { id: 2356, iso: 'hdy', name: 'Hadiyya' },
  { id: 2357, iso: 'hea', name: 'Northern Qiandong Miao' },
  { id: 2360, iso: 'heg', name: 'Helong' },
  { id: 2361, iso: 'heh', name: 'Hehe' },
  { id: 2363, iso: 'hem', name: 'Hemba' },
  { id: 2364, iso: 'her', name: 'Herero' },
  { id: 2373, iso: 'hif', name: 'Fiji Hindi' },
  { id: 2374, iso: 'hig', name: 'Kamwe' },
  { id: 2388, iso: 'hke', name: 'Hunde' },
  { id: 2391, iso: 'hla', name: 'Halia' },
  { id: 2392, iso: 'hlb', name: 'Halbi' },
  { id: 2395, iso: 'hlt', name: 'Matu Chin' },
  { id: 2400, iso: 'hmd', name: 'Large Flowery Miao' },
  { id: 2413, iso: 'hmq', name: 'Eastern Qiandong Miao' },
  { id: 2414, iso: 'hmr', name: 'Hmar' },
  { id: 2415, iso: 'hms', name: 'Southern Qiandong Miao' },
  { id: 2416, iso: 'hmt', name: 'Hamtai' },
  { id: 2421, iso: 'hmz', name: 'Hmong Shua' },
  { id: 2423, iso: 'hnd', name: 'Southern Hindko' },
  { id: 2424, iso: 'hne', name: 'Chhattisgarhi' },
  { id: 2426, iso: 'hni', name: 'Hani' },
  { id: 2427, iso: 'hnj', name: 'Hmong Njua' },
  { id: 2428, iso: 'hnn', name: 'Hanunoo' },
  { id: 2429, iso: 'hno', name: 'Northern Hindko' },
  { id: 2430, iso: 'hns', name: 'Caribbean Hindustani' },
  { id: 2434, iso: 'hoc', name: 'Ho' },
  { id: 2439, iso: 'hoj', name: 'Hadothi' },
  { id: 2448, iso: 'how', name: 'Honi' },
  { id: 2453, iso: 'hra', name: 'Hrangkhol' },
  { id: 2455, iso: 'hre', name: 'Hre' },
  { id: 2464, iso: 'hrx', name: 'Hunsrik' },
  { id: 2466, iso: 'hsb', name: 'Upper Sorbian' },
  { id: 2483, iso: 'hui', name: 'Huli' },
  { id: 2493, iso: 'hus', name: 'Huastec' },
  { id: 2496, iso: 'huv', name: 'San Mateo Del Mar Huave' },
  { id: 2504, iso: 'hvn', name: 'Sabu' },
  { id: 2507, iso: 'hwc', name: 'Hawai\'i Creole English' },
  { id: 2515, iso: 'iba', name: 'Iban' },
  { id: 2516, iso: 'ibb', name: 'Ibibio' },
  { id: 2519, iso: 'ibg', name: 'Ibanag' },
  { id: 2520, iso: 'ibl', name: 'Ibaloi' },
  { id: 2531, iso: 'ida', name: 'Idakho-Isukha-Tiriki' },
  { id: 2534, iso: 'idd', name: 'Ede Idaca' },
  { id: 2541, iso: 'idu', name: 'Idoma' },
  { id: 2542, iso: 'ifa', name: 'Amganad Ifugao' },
  { id: 2543, iso: 'ifb', name: 'Batad Ifugao' },
  { id: 2544, iso: 'ife', name: 'Ifè' },
  { id: 2546, iso: 'ifk', name: 'Tuwali Ifugao' },
  { id: 2548, iso: 'ifu', name: 'Mayoyao Ifugao' },
  { id: 2550, iso: 'igb', name: 'Ebira' },
  { id: 2551, iso: 'ige', name: 'Igede' },
  { id: 2553, iso: 'igl', name: 'Igala' },
  { id: 2563, iso: 'iii', name: 'Sichuan Yi' },
  { id: 2565, iso: 'ijc', name: 'Izon' },
  { id: 2568, iso: 'ijn', name: 'Kalabari' },
  { id: 2569, iso: 'ijs', name: 'Southeast Ijo' },
  { id: 2570, iso: 'ike', name: 'Eastern Canadian Inuktitut' },
  { id: 2572, iso: 'ikk', name: 'Ika' },
  { id: 2580, iso: 'ikw', name: 'Ikwere' },
  { id: 2584, iso: 'ilb', name: 'Ila' },
  { id: 2588, iso: 'ilk', name: 'Ilongot' },
  { id: 2589, iso: 'ill', name: 'Iranun' },
  { id: 2599, iso: 'imo', name: 'Imbongu' },
  { id: 2607, iso: 'inh', name: 'Ingush' },
  { id: 2612, iso: 'ino', name: 'Inoke-Yate' },
  { id: 2617, iso: 'ior', name: 'Inor' },
  { id: 2620, iso: 'ipi', name: 'Ipili' },
  { id: 2624, iso: 'iqw', name: 'Ikwo' },
  { id: 2628, iso: 'irk', name: 'Iraqw' },
  { id: 2631, iso: 'iru', name: 'Irula' },
  { id: 2633, iso: 'iry', name: 'Iraya' },
  { id: 2636, iso: 'isd', name: 'Isnag' },
  { id: 2639, iso: 'ish', name: 'Esan' },
  { id: 2645, iso: 'iso', name: 'Isoko' },
  { id: 2658, iso: 'its', name: 'Isekiri' },
  { id: 2660, iso: 'itv', name: 'Itawit' },
  { id: 2665, iso: 'ium', name: 'Iu Mien' },
  { id: 2667, iso: 'ivv', name: 'Ivatan' },
  { id: 2673, iso: 'ixl', name: 'Ixil' },
  { id: 2678, iso: 'izr', name: 'Izere' },
  { id: 2679, iso: 'izz', name: 'Izii' },
  { id: 2681, iso: 'jab', name: 'Hyam' },
  { id: 2690, iso: 'jam', name: 'Jamaican Creole English' },
  { id: 2698, iso: 'jax', name: 'Jambi Malay' },
  { id: 2705, iso: 'jbn', name: 'Nafusi' },
  { id: 2714, iso: 'jdg', name: 'Jadgali' },
  { id: 2719, iso: 'jeh', name: 'Jeh' },
  { id: 2730, iso: 'jgo', name: 'Ngomba' },
  { id: 2734, iso: 'jib', name: 'Jibu' },
  { id: 2745, iso: 'jit', name: 'Jita' },
  { id: 2747, iso: 'jiv', name: 'Shuar' },
  { id: 2759, iso: 'jmc', name: 'Machame' },
  { id: 2760, iso: 'jmd', name: 'Yamdena' },
  { id: 2772, iso: 'jnj', name: 'Yemsa' },
  { id: 2774, iso: 'jns', name: 'Jaunsari' },
  { id: 2776, iso: 'jod', name: 'Wojenaka' },
  { id: 2779, iso: 'jow', name: 'Jowulu' },
  { id: 2784, iso: 'jra', name: 'Jarai' },
  { id: 2796, iso: 'juk', name: 'Wapan' },
  { id: 2799, iso: 'jun', name: 'Juang' },
  { id: 2807, iso: 'juy', name: 'Juray' },
  { id: 2809, iso: 'jvn', name: 'Caribbean Javanese' },
  { id: 2814, iso: 'kaa', name: 'Kara-Kalpak' },
  { id: 2815, iso: 'kab', name: 'Kabyle' },
  { id: 2816, iso: 'kac', name: 'Kachin' },
  { id: 2817, iso: 'kad', name: 'Adara' },
  { id: 2822, iso: 'kai', name: 'Karekare' },
  { id: 2823, iso: 'kaj', name: 'Jju' },
  { id: 2824, iso: 'kak', name: 'Kayapa Kallahan' },
  { id: 2825, iso: 'kal', name: 'Kalaallisut' },
  { id: 2826, iso: 'kam', name: 'Kamba (Kenya)' },
  { id: 2828, iso: 'kao', name: 'Xaasongaxango' },
  { id: 2831, iso: 'kas', name: 'Kashmiri' },
  { id: 2842, iso: 'kbd', name: 'Kabardian' },
  { id: 2850, iso: 'kbl', name: 'Kanembu' },
  { id: 2852, iso: 'kbn', name: 'Kare (Central African Republic)' },
  { id: 2853, iso: 'kbo', name: 'Keliko' },
  { id: 2854, iso: 'kbp', name: 'Kabiyè' },
  { id: 2855, iso: 'kbq', name: 'Kamano' },
  { id: 2856, iso: 'kbr', name: 'Kafa' },
  { id: 2862, iso: 'kbx', name: 'Ap Ma' },
  { id: 2863, iso: 'kby', name: 'Manga Kanuri' },
  { id: 2871, iso: 'kcg', name: 'Tyap' },
  { id: 2875, iso: 'kck', name: 'Kalanga' },
  { id: 2894, iso: 'kde', name: 'Makonde' },
  { id: 2896, iso: 'kdg', name: 'Seba' },
  { id: 2897, iso: 'kdh', name: 'Tem' },
  { id: 2898, iso: 'kdi', name: 'Kumam' },
  { id: 2899, iso: 'kdj', name: 'Karamojong' },
  { id: 2901, iso: 'kdl', name: 'Tsikimba' },
  { id: 2903, iso: 'kdn', name: 'Kunda' },
  { id: 2907, iso: 'kdt', name: 'Kuy' },
  { id: 2913, iso: 'kea', name: 'Kabuverdianu' },
  { id: 2916, iso: 'ked', name: 'Kerewe' },
  { id: 2923, iso: 'kek', name: 'Kekchí' },
  { id: 2924, iso: 'kel', name: 'Kela (Democratic Republic of Congo)' },
  { id: 2926, iso: 'ken', name: 'Kenyang' },
  { id: 2927, iso: 'keo', name: 'Kakwa' },
  { id: 2930, iso: 'ker', name: 'Kera' },
  { id: 2935, iso: 'kew', name: 'West Kewa' },
  { id: 2936, iso: 'kex', name: 'Kukna' },
  { id: 2938, iso: 'kez', name: 'Kukele' },
  { id: 2939, iso: 'kfa', name: 'Kodava' },
  { id: 2940, iso: 'kfb', name: 'Northwestern Kolami' },
  { id: 2941, iso: 'kfc', name: 'Konda-Dora' },
  { id: 2944, iso: 'kff', name: 'Koya' },
  { id: 2947, iso: 'kfi', name: 'Kannada Kurumba' },
  { id: 2949, iso: 'kfk', name: 'Kinnauri' },
  { id: 2955, iso: 'kfq', name: 'Korku' },
  { id: 2956, iso: 'kfr', name: 'Kachchi' },
  { id: 2957, iso: 'kfs', name: 'Bilaspuri' },
  { id: 2962, iso: 'kfx', name: 'Kullu Pahari' },
  { id: 2963, iso: 'kfy', name: 'Kumaoni' },
  { id: 2964, iso: 'kfz', name: 'Koromfé' },
  { id: 2968, iso: 'kgd', name: 'Kataang' },
  { id: 2969, iso: 'kge', name: 'Komering' },
  { id: 2974, iso: 'kgk', name: 'Kaiwá' },
  { id: 2978, iso: 'kgo', name: 'Krongo' },
  { id: 2989, iso: 'kha', name: 'Khasi' },
  { id: 2990, iso: 'khb', name: 'Lü' },
  { id: 2991, iso: 'khc', name: 'Tukang Besi North' },
  { id: 2995, iso: 'khg', name: 'Khams Tibetan' },
  { id: 3004, iso: 'khq', name: 'Koyra Chiini Songhay' },
  { id: 3005, iso: 'khr', name: 'Kharia' },
  { id: 3008, iso: 'khu', name: 'Nkhumbi' },
  { id: 3010, iso: 'khw', name: 'Khowar' },
  { id: 3012, iso: 'khy', name: 'Kele (Democratic Republic of Congo)' },
  { id: 3013, iso: 'khz', name: 'Keapara' },
  { id: 3014, iso: 'kia', name: 'Kim' },
  { id: 3015, iso: 'kib', name: 'Koalib' },
  { id: 3023, iso: 'kij', name: 'Kilivila' },
  { id: 3024, iso: 'kik', name: 'Kikuyu' },
  { id: 3034, iso: 'kiu', name: 'Kirmanjki (individual language)' },
  { id: 3037, iso: 'kix', name: 'Khiamniungan Naga' },
  { id: 3041, iso: 'kjb', name: 'Q\'anjob\'al' },
  { id: 3042, iso: 'kjc', name: 'Coastal Konjo' },
  { id: 3044, iso: 'kje', name: 'Kisar' },
  { id: 3046, iso: 'kjg', name: 'Khmu' },
  { id: 3047, iso: 'kjh', name: 'Khakas' },
  { id: 3050, iso: 'kjk', name: 'Highland Konjo' },
  { id: 3051, iso: 'kjl', name: 'Western Parbate Kham' },
  { id: 3055, iso: 'kjp', name: 'Pwo Eastern Karen' },
  { id: 3058, iso: 'kjs', name: 'East Kewa' },
  { id: 3062, iso: 'kjy', name: 'Erave' },
  { id: 3071, iso: 'kkh', name: 'Khün' },
  { id: 3072, iso: 'kki', name: 'Kagulu' },
  { id: 3073, iso: 'kkj', name: 'Kako' },
  { id: 3085, iso: 'kkv', name: 'Kangean' },
  { id: 3096, iso: 'klg', name: 'Tagakaulo' },
  { id: 3110, iso: 'klu', name: 'Klao' },
  { id: 3117, iso: 'kmb', name: 'Kimbundu' },
  { id: 3118, iso: 'kmc', name: 'Southern Dong' },
  { id: 3122, iso: 'kmg', name: 'Kâte' },
  { id: 3123, iso: 'kmh', name: 'Kalam' },
  { id: 3126, iso: 'kmk', name: 'Limos Kalinga' },
  { id: 3127, iso: 'kml', name: 'Tanudan Kalinga' },
  { id: 3128, iso: 'kmm', name: 'Kom (India)' },
  { id: 3133, iso: 'kmr', name: 'Northern Kurdish' },
  { id: 3138, iso: 'kmw', name: 'Komo (Democratic Republic of Congo)' },
  { id: 3141, iso: 'kmz', name: 'Khorasani Turkish' },
  { id: 3142, iso: 'kna', name: 'Dera (Nigeria)' },
  { id: 3143, iso: 'knb', name: 'Lubuagan Kalinga' },
  { id: 3144, iso: 'knc', name: 'Central Kanuri' },
  { id: 3146, iso: 'kne', name: 'Kankanaey' },
  { id: 3147, iso: 'knf', name: 'Mankanya' },
  { id: 3150, iso: 'knj', name: 'Western Kanjobal' },
  { id: 3151, iso: 'knk', name: 'Kuranko' },
  { id: 3155, iso: 'kno', name: 'Kono (Sierra Leone)' },
  { id: 3156, iso: 'knp', name: 'Kwanja' },
  { id: 3164, iso: 'knx', name: 'Kendayan' },
  { id: 3165, iso: 'kny', name: 'Kanyok' },
  { id: 3174, iso: 'koi', name: 'Komi-Permyak' },
  { id: 3180, iso: 'koo', name: 'Konzo' },
  { id: 3197, iso: 'kpf', name: 'Komba' },
  { id: 3204, iso: 'kpm', name: 'Koho' },
  { id: 3206, iso: 'kpo', name: 'Ikposo' },
  { id: 3212, iso: 'kpv', name: 'Komi-Zyrian' },
  { id: 3213, iso: 'kpw', name: 'Kobon' },
  { id: 3216, iso: 'kpz', name: 'Kupsabiny' },
  { id: 3221, iso: 'kqe', name: 'Kalagan' },
  { id: 3227, iso: 'kqk', name: 'Kotafon Gbe' },
  { id: 3230, iso: 'kqn', name: 'Kaonde' },
  { id: 3231, iso: 'kqo', name: 'Eastern Krahn' },
  { id: 3232, iso: 'kqp', name: 'Kimré' },
  { id: 3235, iso: 'kqs', name: 'Northern Kissi' },
  { id: 3241, iso: 'kqy', name: 'Koorete' },
  { id: 3245, iso: 'krc', name: 'Karachay-Balkar' },
  { id: 3250, iso: 'kri', name: 'Krio' },
  { id: 3251, iso: 'krj', name: 'Kinaray-A' },
  { id: 3253, iso: 'krl', name: 'Karelian' },
  { id: 3255, iso: 'krn', name: 'Sapo' },
  { id: 3258, iso: 'krs', name: 'Gbaya (Sudan)' },
  { id: 3260, iso: 'kru', name: 'Kurukh' },
  { id: 3262, iso: 'krw', name: 'Western Krahn' },
  { id: 3267, iso: 'ksb', name: 'Shambala' },
  { id: 3268, iso: 'ksc', name: 'Southern Kalinga' },
  { id: 3269, iso: 'ksd', name: 'Kuanua' },
  { id: 3271, iso: 'ksf', name: 'Bafia' },
  { id: 3273, iso: 'ksh', name: 'Kölsch' },
  { id: 3281, iso: 'ksp', name: 'Kaba' },
  { id: 3284, iso: 'kss', name: 'Southern Kisi' },
  { id: 3288, iso: 'ksw', name: 'S\'gaw Karen' },
  { id: 3293, iso: 'ktb', name: 'Kambaata' },
  { id: 3307, iso: 'ktp', name: 'Kaduo' },
  { id: 3312, iso: 'ktu', name: 'Kituba (Democratic Republic of Congo)' },
  { id: 3318, iso: 'kua', name: 'Kuanyama' },
  { id: 3319, iso: 'kub', name: 'Kutep' },
  { id: 3322, iso: 'kue', name: 'Kuman' },
  { id: 3327, iso: 'kuj', name: 'Kuria' },
  { id: 3330, iso: 'kum', name: 'Kumyk' },
  { id: 3331, iso: 'kun', name: 'Kunama' },
  { id: 3336, iso: 'kus', name: 'Kusaal' },
  { id: 3361, iso: 'kvr', name: 'Kerinci' },
  { id: 3367, iso: 'kvx', name: 'Parkari Koli' },
  { id: 3373, iso: 'kwd', name: 'Kwaio' },
  { id: 3375, iso: 'kwf', name: 'Kwara\'ae' },
  { id: 3376, iso: 'kwg', name: 'Sara Kaba Deme' },
  { id: 3379, iso: 'kwj', name: 'Kwanga' },
  { id: 3381, iso: 'kwl', name: 'Kofyar' },
  { id: 3382, iso: 'kwm', name: 'Kwambi' },
  { id: 3383, iso: 'kwn', name: 'Kwangali' },
  { id: 3391, iso: 'kwv', name: 'Sara Kaba Náà' },
  { id: 3394, iso: 'kwy', name: 'San Salvador Kongo' },
  { id: 3398, iso: 'kxc', name: 'Konso' },
  { id: 3399, iso: 'kxd', name: 'Brunei' },
  { id: 3407, iso: 'kxm', name: 'Northern Khmer' },
  { id: 3410, iso: 'kxp', name: 'Wadiyara Koli' },
  { id: 3415, iso: 'kxu', name: 'Kui (India)' },
  { id: 3416, iso: 'kxv', name: 'Kuvi' },
  { id: 3421, iso: 'kya', name: 'Kwaya' },
  { id: 3422, iso: 'kyb', name: 'Butbut Kalinga' },
  { id: 3423, iso: 'kyc', name: 'Kyaka' },
  { id: 3425, iso: 'kye', name: 'Krache' },
  { id: 3426, iso: 'kyf', name: 'Kouya' },
  { id: 3427, iso: 'kyg', name: 'Keyagana' },
  { id: 3431, iso: 'kyk', name: 'Kamayo' },
  { id: 3437, iso: 'kyq', name: 'Kenga' },
  { id: 3441, iso: 'kyu', name: 'Western Kayah' },
  { id: 3449, iso: 'kzc', name: 'Bondoukou Kulango' },
  { id: 3452, iso: 'kzf', name: 'Da\'a Kaili' },
  { id: 3459, iso: 'kzn', name: 'Kokola' },
  { id: 3463, iso: 'kzr', name: 'Karang' },
  { id: 3472, iso: 'laa', name: 'Southern Subanen' },
  { id: 3475, iso: 'lad', name: 'Ladino' },
  { id: 3478, iso: 'lag', name: 'Langi' },
  { id: 3481, iso: 'laj', name: 'Lango (Uganda)' },
  { id: 3484, iso: 'lam', name: 'Lamba' },
  { id: 3487, iso: 'lap', name: 'Laka (Chad)' },
  { id: 3490, iso: 'las', name: 'Lama (Togo)' },
  { id: 3501, iso: 'lbe', name: 'Lak' },
  { id: 3505, iso: 'lbj', name: 'Ladakhi' },
  { id: 3506, iso: 'lbk', name: 'Central Bontok' },
  { id: 3517, iso: 'lbw', name: 'Tolaki' },
  { id: 3518, iso: 'lbx', name: 'Lawangan' },
  { id: 3525, iso: 'lch', name: 'Luchazi' },
  { id: 3527, iso: 'lcm', name: 'Tungag' },
  { id: 3536, iso: 'ldi', name: 'Laari' },
  { id: 3545, iso: 'lea', name: 'Lega-Shabunda' },
  { id: 3546, iso: 'leb', name: 'Lala-Bisa' },
  { id: 3548, iso: 'led', name: 'Lendu' },
  { id: 3549, iso: 'lee', name: 'Lyélé' },
  { id: 3550, iso: 'lef', name: 'Lelemi' },
  { id: 3551, iso: 'leh', name: 'Lenje' },
  { id: 3553, iso: 'lej', name: 'Lengola' },
  { id: 3566, iso: 'lew', name: 'Ledo Kaili' },
  { id: 3567, iso: 'lex', name: 'Luang' },
  { id: 3569, iso: 'lez', name: 'Lezghian' },
  { id: 3574, iso: 'lgg', name: 'Lugbara' },
  { id: 3588, iso: 'lhi', name: 'Lahu Shi' },
  { id: 3595, iso: 'lhu', name: 'Lahu' },
  { id: 3596, iso: 'lia', name: 'West-Central Limba' },
  { id: 3598, iso: 'lic', name: 'Hlai' },
  { id: 3601, iso: 'lif', name: 'Limbu' },
  { id: 3603, iso: 'lih', name: 'Lihir' },
  { id: 3605, iso: 'lij', name: 'Ligurian' },
  { id: 3606, iso: 'lik', name: 'Lika' },
  { id: 3608, iso: 'lim', name: 'Limburgan' },
  { id: 3611, iso: 'lip', name: 'Sekpele' },
  { id: 3614, iso: 'lis', name: 'Lisu' },
  { id: 3618, iso: 'liw', name: 'Col' },
  { id: 3620, iso: 'liy', name: 'Banda-Bambari' },
  { id: 3625, iso: 'ljl', name: 'Li\'o' },
  { id: 3626, iso: 'ljp', name: 'Lampung Api' },
  { id: 3630, iso: 'lkb', name: 'Kabras' },
  { id: 3635, iso: 'lki', name: 'Laki' },
  { id: 3640, iso: 'lko', name: 'Khayo' },
  { id: 3641, iso: 'lkr', name: 'Päri' },
  { id: 3642, iso: 'lks', name: 'Kisa' },
  { id: 3647, iso: 'llb', name: 'Lolo' },
  { id: 3659, iso: 'lln', name: 'Lele (Chad)' },
  { id: 3664, iso: 'llu', name: 'Lau' },
  { id: 3669, iso: 'lmd', name: 'Lumun' },
  { id: 3676, iso: 'lmk', name: 'Lamkang' },
  { id: 3678, iso: 'lmn', name: 'Lambadi' },
  { id: 3679, iso: 'lmo', name: 'Lombard' },
  { id: 3680, iso: 'lmp', name: 'Limbum' },
  { id: 3691, iso: 'lnd', name: 'Lundayeh' },
  { id: 3696, iso: 'lnl', name: 'South Central Banda' },
  { id: 3700, iso: 'lns', name: 'Lamnso\'' },
  { id: 3704, iso: 'loa', name: 'Loloda' },
  { id: 3705, iso: 'lob', name: 'Lobi' },
  { id: 3706, iso: 'loc', name: 'Inonhan' },
  { id: 3709, iso: 'log', name: 'Logo' },
  { id: 3713, iso: 'lok', name: 'Loko' },
  { id: 3714, iso: 'lol', name: 'Mongo' },
  { id: 3715, iso: 'lom', name: 'Loma (Liberia)' },
  { id: 3716, iso: 'lon', name: 'Malawi Lomwe' },
  { id: 3722, iso: 'lot', name: 'Otuho' },
  { id: 3728, iso: 'loz', name: 'Lozi' },
  { id: 3732, iso: 'lpo', name: 'Lipo' },
  { id: 3735, iso: 'lrc', name: 'Northern Luri' },
  { id: 3738, iso: 'lri', name: 'Marachi' },
  { id: 3741, iso: 'lrm', name: 'Marama' },
  { id: 3743, iso: 'lro', name: 'Laro' },
  { id: 3753, iso: 'lsi', name: 'Lashi' },
  { id: 3755, iso: 'lsm', name: 'Saamia' },
  { id: 3763, iso: 'ltg', name: 'Latgalian' },
  { id: 3766, iso: 'lto', name: 'Tsotso' },
  { id: 3767, iso: 'lts', name: 'Tachoni' },
  { id: 3769, iso: 'ltz', name: 'Luxembourgish' },
  { id: 3771, iso: 'lub', name: 'Luba-Katanga' },
  { id: 3772, iso: 'luc', name: 'Aringa' },
  { id: 3774, iso: 'lue', name: 'Luvale' },
  { id: 3776, iso: 'lug', name: 'Ganda' },
  { id: 3782, iso: 'lun', name: 'Lunda' },
  { id: 3783, iso: 'luo', name: 'Luo (Kenya and Tanzania)' },
  { id: 3787, iso: 'lus', name: 'Lushai' },
  { id: 3793, iso: 'luz', name: 'Southern Luri' },
  { id: 3796, iso: 'lvs', name: 'Standard Latvian' },
  { id: 3800, iso: 'lwg', name: 'Wanga' },
  { id: 3804, iso: 'lwo', name: 'Luwo' },
  { id: 3817, iso: 'mad', name: 'Madurese' },
  { id: 3819, iso: 'maf', name: 'Mafa' },
  { id: 3820, iso: 'mag', name: 'Magahi' },
  { id: 3821, iso: 'mah', name: 'Marshallese' },
  { id: 3824, iso: 'mak', name: 'Makasar' },
  { id: 3826, iso: 'mam', name: 'Mam' },
  { id: 3830, iso: 'mas', name: 'Masai' },
  { id: 3834, iso: 'maw', name: 'Mampruli' },
  { id: 3835, iso: 'max', name: 'North Moluccan Malay' },
  { id: 3836, iso: 'maz', name: 'Central Mazahua' },
  { id: 3837, iso: 'mba', name: 'Higaonon' },
  { id: 3838, iso: 'mbb', name: 'Western Bukidnon Manobo' },
  { id: 3840, iso: 'mbd', name: 'Dibabawon Manobo' },
  { id: 3844, iso: 'mbi', name: 'Ilianen Manobo' },
  { id: 3854, iso: 'mbs', name: 'Sarangani Manobo' },
  { id: 3855, iso: 'mbt', name: 'Matigsalug Manobo' },
  { id: 3856, iso: 'mbu', name: 'Mbula-Bwazza' },
  { id: 3858, iso: 'mbw', name: 'Maring' },
  { id: 3872, iso: 'mck', name: 'Mbunda' },
  { id: 3875, iso: 'mcn', name: 'Masana' },
  { id: 3877, iso: 'mcp', name: 'Makaa' },
  { id: 3878, iso: 'mcq', name: 'Ese' },
  { id: 3879, iso: 'mcr', name: 'Menya' },
  { id: 3882, iso: 'mcu', name: 'Cameroon Mambila' },
  { id: 3888, iso: 'mda', name: 'Mada (Nigeria)' },
  { id: 3891, iso: 'mdd', name: 'Mbum' },
  { id: 3892, iso: 'mde', name: 'Maba (Chad)' },
  { id: 3895, iso: 'mdh', name: 'Maguindanaon' },
  { id: 3897, iso: 'mdj', name: 'Mangbetu' },
  { id: 3900, iso: 'mdm', name: 'Mayogo' },
  { id: 3902, iso: 'mdp', name: 'Mbala' },
  { id: 3903, iso: 'mdq', name: 'Mbole' },
  { id: 3904, iso: 'mdr', name: 'Mandar' },
  { id: 3909, iso: 'mdw', name: 'Mbosi' },
  { id: 3910, iso: 'mdx', name: 'Dizin' },
  { id: 3911, iso: 'mdy', name: 'Male (Ethiopia)' },
  { id: 3916, iso: 'med', name: 'Melpa' },
  { id: 3921, iso: 'mej', name: 'Meyah' },
  { id: 3922, iso: 'mek', name: 'Mekeo' },
  { id: 3923, iso: 'mel', name: 'Central Melanau' },
  { id: 3925, iso: 'men', name: 'Mende (Sierra Leone)' },
  { id: 3926, iso: 'meo', name: 'Kedah Malay' },
  { id: 3928, iso: 'meq', name: 'Merey' },
  { id: 3929, iso: 'mer', name: 'Meru' },
  { id: 3932, iso: 'meu', name: 'Motu' },
  { id: 3933, iso: 'mev', name: 'Mano' },
  { id: 3935, iso: 'mey', name: 'Hassaniyya' },
  { id: 3937, iso: 'mfa', name: 'Pattani Malay' },
  { id: 3938, iso: 'mfb', name: 'Bangka' },
  { id: 3941, iso: 'mfe', name: 'Morisyen' },
  { id: 3944, iso: 'mfh', name: 'Matal' },
  { id: 3945, iso: 'mfi', name: 'Wandala' },
  { id: 3947, iso: 'mfk', name: 'North Mofu' },
  { id: 3949, iso: 'mfm', name: 'Marghi South' },
  { id: 3950, iso: 'mfn', name: 'Cross River Mbembe' },
  { id: 3951, iso: 'mfo', name: 'Mbe' },
  { id: 3953, iso: 'mfq', name: 'Moba' },
  { id: 3957, iso: 'mfu', name: 'Mbwela' },
  { id: 3958, iso: 'mfv', name: 'Mandjak' },
  { id: 3961, iso: 'mfy', name: 'Mayo' },
  { id: 3962, iso: 'mfz', name: 'Mabaan' },
  { id: 3966, iso: 'mgd', name: 'Moru' },
  { id: 3967, iso: 'mge', name: 'Mango' },
  { id: 3970, iso: 'mgh', name: 'Makhuwa-Meetto' },
  { id: 3971, iso: 'mgi', name: 'Lijili' },
  { id: 3975, iso: 'mgm', name: 'Mambae' },
  { id: 3977, iso: 'mgo', name: 'Meta\'' },
  { id: 3978, iso: 'mgp', name: 'Eastern Magar' },
  { id: 3980, iso: 'mgr', name: 'Mambwe-Lungu' },
  { id: 3984, iso: 'mgv', name: 'Matengo' },
  { id: 3995, iso: 'mhi', name: 'Ma\'di' },
  { id: 3999, iso: 'mhm', name: 'Makhuwa-Moniga' },
  { id: 4004, iso: 'mhr', name: 'Eastern Mari' },
  { id: 4009, iso: 'mhx', name: 'Maru' },
  { id: 4010, iso: 'mhy', name: 'Ma\'anyan' },
  { id: 4017, iso: 'mif', name: 'Mofu-Gudur' },
  { id: 4024, iso: 'mim', name: 'Alacatlatzala Mixtec' },
  { id: 4025, iso: 'min', name: 'Minangkabau' },
  { id: 4028, iso: 'miq', name: 'Mískito' },
  { id: 4029, iso: 'mir', name: 'Isthmus Mixe' },
  { id: 4040, iso: 'mjg', name: 'Tu' },
  { id: 4042, iso: 'mji', name: 'Kim Mun' },
  { id: 4045, iso: 'mjl', name: 'Mandeali' },
  { id: 4053, iso: 'mjt', name: 'Sauria Paharia' },
  { id: 4056, iso: 'mjw', name: 'Karbi' },
  { id: 4064, iso: 'mke', name: 'Mawchi' },
  { id: 4067, iso: 'mki', name: 'Dhatki' },
  { id: 4070, iso: 'mkl', name: 'Mokole' },
  { id: 4072, iso: 'mkn', name: 'Kupang Malay' },
  { id: 4077, iso: 'mks', name: 'Silacayoapan Mixtec' },
  { id: 4079, iso: 'mku', name: 'Konyanka Maninka' },
  { id: 4081, iso: 'mkw', name: 'Kituba (Congo)' },
  { id: 4084, iso: 'mkz', name: 'Makasae' },
  { id: 4087, iso: 'mlc', name: 'Cao Lan' },
  { id: 4089, iso: 'mlf', name: 'Mal' },
  { id: 4100, iso: 'mlq', name: 'Western Maninkakan' },
  { id: 4102, iso: 'mls', name: 'Masalit' },
  { id: 4104, iso: 'mlu', name: 'To\'abaita' },
  { id: 4126, iso: 'mmr', name: 'Western Xiangxi Miao' },
  { id: 4132, iso: 'mmy', name: 'Migaama' },
  { id: 4135, iso: 'mnb', name: 'Muna' },
  { id: 4138, iso: 'mne', name: 'Naba' },
  { id: 4139, iso: 'mnf', name: 'Mundani' },
  { id: 4144, iso: 'mnk', name: 'Mandinka' },
  { id: 4154, iso: 'mnw', name: 'Mon' },
  { id: 4155, iso: 'mnx', name: 'Manikion' },
  { id: 4156, iso: 'mny', name: 'Manyawa' },
  { id: 4157, iso: 'mnz', name: 'Moni' },
  { id: 4158, iso: 'moa', name: 'Mwan' },
  { id: 4162, iso: 'mog', name: 'Mongondow' },
  { id: 4172, iso: 'mor', name: 'Moro' },
  { id: 4173, iso: 'mos', name: 'Mossi' },
  { id: 4179, iso: 'moy', name: 'Shekkacho' },
  { id: 4180, iso: 'moz', name: 'Mukulu' },
  { id: 4185, iso: 'mpe', name: 'Majang' },
  { id: 4186, iso: 'mpg', name: 'Marba' },
  { id: 4198, iso: 'mps', name: 'Dadibi' },
  { id: 4203, iso: 'mpx', name: 'Misima-Panaeati' },
  { id: 4207, iso: 'mqb', name: 'Mbuko' },
  { id: 4214, iso: 'mqj', name: 'Mamasa' },
  { id: 4216, iso: 'mql', name: 'Mbelime' },
  { id: 4229, iso: 'mqy', name: 'Manggarai' },
  { id: 4234, iso: 'mrd', name: 'Western Magar' },
  { id: 4237, iso: 'mrg', name: 'Mising' },
  { id: 4238, iso: 'mrh', name: 'Mara Chin' },
  { id: 4239, iso: 'mri', name: 'Maori' },
  { id: 4240, iso: 'mrj', name: 'Western Mari' },
  { id: 4244, iso: 'mrn', name: 'Cheke Holo' },
  { id: 4245, iso: 'mro', name: 'Mru' },
  { id: 4248, iso: 'mrr', name: 'Maria (India)' },
  { id: 4250, iso: 'mrt', name: 'Marghi Central' },
  { id: 4253, iso: 'mrw', name: 'Maranao' },
  { id: 4255, iso: 'mry', name: 'Mandaya' },
  { id: 4258, iso: 'msb', name: 'Masbatenyo' },
  { id: 4261, iso: 'mse', name: 'Musey' },
  { id: 4264, iso: 'msh', name: 'Masikoro Malagasy' },
  { id: 4267, iso: 'msk', name: 'Mansaka' },
  { id: 4269, iso: 'msm', name: 'Agusan Manobo' },
  { id: 4282, iso: 'mta', name: 'Cotabato Manobo' },
  { id: 4283, iso: 'mtb', name: 'Anyin Morofo' },
  { id: 4285, iso: 'mtd', name: 'Mualang' },
  { id: 4298, iso: 'mtq', name: 'Muong' },
  { id: 4299, iso: 'mtr', name: 'Mewari' },
  { id: 4307, iso: 'mua', name: 'Mundang' },
  { id: 4312, iso: 'mug', name: 'Musgu' },
  { id: 4313, iso: 'muh', name: 'Mündü' },
  { id: 4314, iso: 'mui', name: 'Musi' },
  { id: 4320, iso: 'mup', name: 'Malvi' },
  { id: 4322, iso: 'mur', name: 'Murle' },
  { id: 4324, iso: 'mut', name: 'Western Muria' },
  { id: 4327, iso: 'mux', name: 'Bo-Ung' },
  { id: 4328, iso: 'muy', name: 'Muyang' },
  { id: 4333, iso: 'mve', name: 'Marwari (Pakistan)' },
  { id: 4343, iso: 'mvp', name: 'Duri' },
  { id: 4352, iso: 'mvy', name: 'Indus Kohistani' },
  { id: 4353, iso: 'mvz', name: 'Mesqan' },
  { id: 4357, iso: 'mwe', name: 'Mwera (Chimwera)' },
  { id: 4363, iso: 'mwk', name: 'Kita Maninkakan' },
  { id: 4364, iso: 'mwl', name: 'Mirandese' },
  { id: 4365, iso: 'mwm', name: 'Sar' },
  { id: 4366, iso: 'mwn', name: 'Nyamwanga' },
  { id: 4369, iso: 'mwq', name: 'Mün Chin' },
  { id: 4374, iso: 'mwv', name: 'Mentawai' },
  { id: 4375, iso: 'mww', name: 'Hmong Daw' },
  { id: 4381, iso: 'mxc', name: 'Manyika' },
  { id: 4385, iso: 'mxg', name: 'Mbangala' },
  { id: 4402, iso: 'mxx', name: 'Mahou' },
  { id: 4406, iso: 'myb', name: 'Mbay' },
  { id: 4413, iso: 'myi', name: 'Mina (India)' },
  { id: 4415, iso: 'myk', name: 'Mamara Senoufo' },
  { id: 4417, iso: 'mym', name: 'Me\'en' },
  { id: 4423, iso: 'myv', name: 'Erzya' },
  { id: 4425, iso: 'myx', name: 'Masaaba' },
  { id: 4429, iso: 'mzb', name: 'Tumzabt' },
  { id: 4438, iso: 'mzl', name: 'Mazatlán Mixe' },
  { id: 4439, iso: 'mzm', name: 'Mumuye' },
  { id: 4440, iso: 'mzn', name: 'Mazanderani' },
  { id: 4448, iso: 'mzv', name: 'Manza' },
  { id: 4449, iso: 'mzw', name: 'Deg' },
  { id: 4458, iso: 'naf', name: 'Nabak' },
  { id: 4459, iso: 'nag', name: 'Naga Pidgin' },
  { id: 4461, iso: 'nak', name: 'Nakanai' },
  { id: 4466, iso: 'nap', name: 'Neapolitan' },
  { id: 4467, iso: 'naq', name: 'Nama (Namibia)' },
  { id: 4469, iso: 'nas', name: 'Naasioi' },
  { id: 4472, iso: 'nav', name: 'Navajo' },
  { id: 4473, iso: 'naw', name: 'Nawuri' },
  { id: 4477, iso: 'nba', name: 'Nyemba' },
  { id: 4479, iso: 'nbc', name: 'Chang Naga' },
  { id: 4481, iso: 'nbe', name: 'Konyak Naga' },
  { id: 4484, iso: 'nbi', name: 'Mao Naga' },
  { id: 4487, iso: 'nbl', name: 'South Ndebele' },
  { id: 4488, iso: 'nbm', name: 'Ngbaka Ma\'bo' },
  { id: 4496, iso: 'nbu', name: 'Rongmei Naga' },
  { id: 4498, iso: 'nbw', name: 'Southern Ngbandi' },
  { id: 4501, iso: 'ncb', name: 'Central Nicobarese' },
  { id: 4507, iso: 'nch', name: 'Central Huasteca Nahuatl' },
  { id: 4519, iso: 'ncu', name: 'Chumburung' },
  { id: 4524, iso: 'ndc', name: 'Ndau' },
  { id: 4526, iso: 'nde', name: 'North Ndebele' },
  { id: 4529, iso: 'ndh', name: 'Ndali' },
  { id: 4530, iso: 'ndi', name: 'Samba Leko' },
  { id: 4536, iso: 'ndo', name: 'Ndonga' },
  { id: 4537, iso: 'ndp', name: 'Ndo' },
  { id: 4543, iso: 'ndv', name: 'Ndut' },
  { id: 4545, iso: 'ndx', name: 'Nduga' },
  { id: 4547, iso: 'ndz', name: 'Ndogo' },
  { id: 4549, iso: 'neb', name: 'Toura (Côte d\'Ivoire)' },
  { id: 4563, iso: 'neq', name: 'North Central Mixe' },
  { id: 4569, iso: 'new', name: 'Newari' },
  { id: 4576, iso: 'nfr', name: 'Nafaanra' },
  { id: 4578, iso: 'nga', name: 'Ngbaka' },
  { id: 4579, iso: 'ngb', name: 'Northern Ngbandi' },
  { id: 4580, iso: 'ngc', name: 'Ngombe (Democratic Republic of Congo)' },
  { id: 4588, iso: 'ngl', name: 'Lomwe' },
  { id: 4591, iso: 'ngo', name: 'Ngoni' },
  { id: 4592, iso: 'ngp', name: 'Ngulu' },
  { id: 4597, iso: 'ngu', name: 'Guerrero Nahuatl' },
  { id: 4607, iso: 'nhe', name: 'Eastern Huasteca Nahuatl' },
  { id: 4611, iso: 'nhi', name: 'Zacatlán-Ahuacatlán-Tepetzintla Nahuatl' },
  { id: 4618, iso: 'nhr', name: 'Naro' },
  { id: 4620, iso: 'nhu', name: 'Noone' },
  { id: 4622, iso: 'nhw', name: 'Western Huasteca Nahuatl' },
  { id: 4623, iso: 'nhx', name: 'Isthmus-Mecayapan Nahuatl' },
  { id: 4626, iso: 'nia', name: 'Nias' },
  { id: 4632, iso: 'nih', name: 'Nyiha (Tanzania)' },
  { id: 4633, iso: 'nii', name: 'Nii' },
  { id: 4634, iso: 'nij', name: 'Ngaju' },
  { id: 4637, iso: 'nim', name: 'Nilamba' },
  { id: 4638, iso: 'nin', name: 'Ninzo' },
  { id: 4640, iso: 'niq', name: 'Nandi' },
  { id: 4647, iso: 'nix', name: 'Hema' },
  { id: 4648, iso: 'niy', name: 'Ngiti' },
  { id: 4650, iso: 'nja', name: 'Nzanyi' },
  { id: 4653, iso: 'njh', name: 'Lotha Naga' },
  { id: 4657, iso: 'njm', name: 'Angami Naga' },
  { id: 4658, iso: 'njn', name: 'Liangmai Naga' },
  { id: 4659, iso: 'njo', name: 'Ao Naga' },
  { id: 4666, iso: 'njz', name: 'Nyishi' },
  { id: 4667, iso: 'nka', name: 'Nkoya' },
  { id: 4674, iso: 'nkh', name: 'Khezha Naga' },
  { id: 4675, iso: 'nki', name: 'Thangal Naga' },
  { id: 4680, iso: 'nko', name: 'Nkonya' },
  { id: 4691, iso: 'nla', name: 'Ngombale' },
  { id: 4692, iso: 'nlc', name: 'Nalca' },
  { id: 4694, iso: 'nle', name: 'East Nyala' },
  { id: 4695, iso: 'nlg', name: 'Gela' },
  { id: 4698, iso: 'nlk', name: 'Ninia Yali' },
  { id: 4703, iso: 'nlv', name: 'Orizaba Nahuatl' },
  { id: 4708, iso: 'nma', name: 'Maram Naga' },
  { id: 4710, iso: 'nmc', name: 'Ngam' },
  { id: 4712, iso: 'nme', name: 'Mzieme Naga' },
  { id: 4713, iso: 'nmf', name: 'Tangkhul Naga (India)' },
  { id: 4724, iso: 'nmq', name: 'Nambya' },
  { id: 4733, iso: 'nmz', name: 'Nawdm' },
  { id: 4735, iso: 'nnb', name: 'Nande' },
  { id: 4736, iso: 'nnc', name: 'Nancere' },
  { id: 4740, iso: 'nng', name: 'Maring Naga' },
  { id: 4741, iso: 'nnh', name: 'Ngiemboon' },
  { id: 4745, iso: 'nnl', name: 'Northern Rengma Naga' },
  { id: 4749, iso: 'nnp', name: 'Wancho Naga' },
  { id: 4750, iso: 'nnq', name: 'Ngindo' },
  { id: 4756, iso: 'nnw', name: 'Southern Nuni' },
  { id: 4763, iso: 'nod', name: 'Northern Thai' },
  { id: 4764, iso: 'noe', name: 'Nimadi' },
  { id: 4766, iso: 'nog', name: 'Nogai' },
  { id: 4768, iso: 'noi', name: 'Noiri' },
  { id: 4779, iso: 'nou', name: 'Ewage-Notu' },
  { id: 4781, iso: 'now', name: 'Nyambo' },
  { id: 4787, iso: 'nph', name: 'Phom Naga' },
  { id: 4789, iso: 'npl', name: 'Southeastern Puebla Nahuatl' },
  { id: 4791, iso: 'npo', name: 'Pochuri Naga' },
  { id: 4795, iso: 'nqg', name: 'Southern Nago' },
  { id: 4805, iso: 'nre', name: 'Southern Rengma Naga' },
  { id: 4807, iso: 'nri', name: 'Chokri Naga' },
  { id: 4818, iso: 'nsa', name: 'Sangtam Naga' },
  { id: 4820, iso: 'nsd', name: 'Southern Nisu' },
  { id: 4821, iso: 'nse', name: 'Nsenga' },
  { id: 4828, iso: 'nsm', name: 'Sumi Naga' },
  { id: 4835, iso: 'nst', name: 'Tase Naga' },
  { id: 4839, iso: 'nsx', name: 'Nsongo' },
  { id: 4847, iso: 'ntm', name: 'Nateni' },
  { id: 4848, iso: 'nto', name: 'Ntomba' },
  { id: 4850, iso: 'ntr', name: 'Delo' },
  { id: 4865, iso: 'nuj', name: 'Nyole' },
  { id: 4871, iso: 'nup', name: 'Nupe-Nupe-Tako' },
  { id: 4874, iso: 'nus', name: 'Nuer' },
  { id: 4875, iso: 'nut', name: 'Nung (Viet Nam)' },
  { id: 4886, iso: 'nwb', name: 'Nyabwa' },
  { id: 4897, iso: 'nxd', name: 'Ngando (Democratic Republic of Congo)' },
  { id: 4905, iso: 'nxq', name: 'Naxi' },
  { id: 4909, iso: 'nya', name: 'Nyanja' },
  { id: 4912, iso: 'nyd', name: 'Nyore' },
  { id: 4914, iso: 'nyf', name: 'Giryama' },
  { id: 4917, iso: 'nyi', name: 'Ama (Sudan)' },
  { id: 4918, iso: 'nyj', name: 'Nyanga' },
  { id: 4919, iso: 'nyk', name: 'Nyaneka' },
  { id: 4921, iso: 'nym', name: 'Nyamwezi' },
  { id: 4922, iso: 'nyn', name: 'Nyankole' },
  { id: 4923, iso: 'nyo', name: 'Nyoro' },
  { id: 4929, iso: 'nyu', name: 'Nyungwe' },
  { id: 4933, iso: 'nyy', name: 'Nyakyusa-Ngonde' },
  { id: 4934, iso: 'nza', name: 'Tigon Mbembe' },
  { id: 4935, iso: 'nzb', name: 'Njebi' },
  { id: 4936, iso: 'nzi', name: 'Nzima' },
  { id: 4938, iso: 'nzm', name: 'Zeme Naga' },
  { id: 4941, iso: 'nzy', name: 'Nzakambay' },
  { id: 4951, iso: 'obo', name: 'Obo Manobo' },
  { id: 4957, iso: 'oci', name: 'Occitan (post 1500)' },
  { id: 4963, iso: 'odu', name: 'Odual' },
  { id: 4967, iso: 'ogb', name: 'Ogbia' },
  { id: 4968, iso: 'ogc', name: 'Ogbah' },
  { id: 4971, iso: 'ogo', name: 'Khana' },
  { id: 4998, iso: 'okr', name: 'Kirike' },
  { id: 5000, iso: 'oku', name: 'Oku' },
  { id: 5001, iso: 'okv', name: 'Orokaiva' },
  { id: 5004, iso: 'old', name: 'Mochi' },
  { id: 5008, iso: 'olo', name: 'Livvi' },
  { id: 5028, iso: 'onb', name: 'Lingao' },
  { id: 5030, iso: 'ong', name: 'Olo' },
  { id: 5038, iso: 'ons', name: 'Ono' },
  { id: 5062, iso: 'oro', name: 'Orokolo' },
  { id: 5065, iso: 'ort', name: 'Adivasi Oriya' },
  { id: 5074, iso: 'osi', name: 'Osing' },
  { id: 5077, iso: 'oss', name: 'Ossetian' },
  { id: 5084, iso: 'ote', name: 'Mezquital Otomi' },
  { id: 5088, iso: 'otm', name: 'Eastern Highland Otomi' },
  { id: 5089, iso: 'otn', name: 'Tenango Otomi' },
  { id: 5090, iso: 'otq', name: 'Querétaro Otomi' },
  { id: 5092, iso: 'ots', name: 'Estado de México Otomi' },
  { id: 5111, iso: 'ozm', name: 'Koonzime' },
  { id: 5117, iso: 'pag', name: 'Pangasinan' },
  { id: 5122, iso: 'pam', name: 'Pampanga' },
  { id: 5125, iso: 'pap', name: 'Papiamento' },
  { id: 5130, iso: 'pau', name: 'Palauan' },
  { id: 5142, iso: 'pbi', name: 'Parkwa' },
  { id: 5145, iso: 'pbo', name: 'Papel' },
  { id: 5150, iso: 'pbu', name: 'Northern Pashto' },
  { id: 5151, iso: 'pbv', name: 'Pnar' },
  { id: 5155, iso: 'pcc', name: 'Bouyei' },
  { id: 5157, iso: 'pce', name: 'Ruching Palaung' },
  { id: 5159, iso: 'pcg', name: 'Paniya' },
  { id: 5160, iso: 'pch', name: 'Pardhan' },
  { id: 5163, iso: 'pck', name: 'Paite Chin' },
  { id: 5165, iso: 'pcm', name: 'Nigerian Pidgin' },
  { id: 5170, iso: 'pdc', name: 'Pennsylvania German' },
  { id: 5174, iso: 'pdt', name: 'Plautdietsch' },
  { id: 5175, iso: 'pdu', name: 'Kayan' },
  { id: 5187, iso: 'pem', name: 'Phende' },
  { id: 5197, iso: 'pfe', name: 'Peere' },
  { id: 5199, iso: 'pga', name: 'Sudanese Creole Arabic' },
  { id: 5217, iso: 'phr', name: 'Pahari-Potwari' },
  { id: 5218, iso: 'pht', name: 'Phu Thai' },
  { id: 5219, iso: 'phu', name: 'Phuan' },
  { id: 5232, iso: 'pil', name: 'Yom' },
  { id: 5238, iso: 'pis', name: 'Pijin' },
  { id: 5248, iso: 'pkb', name: 'Pokomo' },
  { id: 5253, iso: 'pko', name: 'Pökoot' },
  { id: 5261, iso: 'plc', name: 'Central Palawano' },
  { id: 5268, iso: 'plk', name: 'Kohistani Shina' },
  { id: 5269, iso: 'pll', name: 'Shwe Palaung' },
  { id: 5279, iso: 'plw', name: 'Brooke\'s Point Palawano' },
  { id: 5287, iso: 'pmf', name: 'Pamona' },
  { id: 5298, iso: 'pms', name: 'Piemontese' },
  { id: 5300, iso: 'pmu', name: 'Mirpur Panjabi' },
  { id: 5302, iso: 'pmx', name: 'Poumei Naga' },
  { id: 5303, iso: 'pmy', name: 'Papuan Malay' },
  { id: 5322, iso: 'pnt', name: 'Pontic' },
  { id: 5327, iso: 'pny', name: 'Pinyin' },
  { id: 5328, iso: 'pnz', name: 'Pana (Central African Republic)' },
  { id: 5329, iso: 'poc', name: 'Poqomam' },
  { id: 5334, iso: 'poh', name: 'Poqomchi\'' },
  { id: 5335, iso: 'poi', name: 'Highland Popoluca' },
  { id: 5339, iso: 'pon', name: 'Pohnpeian' },
  { id: 5346, iso: 'pov', name: 'Upper Guinea Crioulo' },
  { id: 5349, iso: 'poy', name: 'Pogolo' },
  { id: 5353, iso: 'ppk', name: 'Uma' },
  { id: 5367, iso: 'prd', name: 'Parsi-Dari' },
  { id: 5369, iso: 'prf', name: 'Paranan' },
  { id: 5373, iso: 'prk', name: 'Parauk' },
  { id: 5378, iso: 'prp', name: 'Parsi' },
  { id: 5385, iso: 'prx', name: 'Purik' },
  { id: 5391, iso: 'pse', name: 'Central Malay' },
  { id: 5393, iso: 'psh', name: 'Southwest Pashayi' },
  { id: 5394, iso: 'psi', name: 'Southeast Pashayi' },
  { id: 5403, iso: 'pst', name: 'Central Pashto' },
  { id: 5416, iso: 'ptu', name: 'Bambam' },
  { id: 5420, iso: 'pua', name: 'Western Highland Purepecha' },
  { id: 5437, iso: 'puu', name: 'Punu' },
  { id: 5446, iso: 'pwn', name: 'Paiwan' },
  { id: 5447, iso: 'pwo', name: 'Pwo Western Karen' },
  { id: 5448, iso: 'pwr', name: 'Powari' },
  { id: 5449, iso: 'pww', name: 'Pwo Northern Karen' },
  { id: 5460, iso: 'qub', name: 'Huallaga Huánuco Quechua' },
  { id: 5461, iso: 'quc', name: 'K\'iche\'' },
  { id: 5464, iso: 'quf', name: 'Lambayeque Quechua' },
  { id: 5465, iso: 'qug', name: 'Chimborazo Highland Quichua' },
  { id: 5466, iso: 'quh', name: 'South Bolivian Quechua' },
  { id: 5469, iso: 'qul', name: 'North Bolivian Quechua' },
  { id: 5479, iso: 'quy', name: 'Ayacucho Quechua' },
  { id: 5480, iso: 'quz', name: 'Cusco Quechua' },
  { id: 5482, iso: 'qvc', name: 'Cajamarca Quechua' },
  { id: 5483, iso: 'qve', name: 'Eastern Apurímac Quechua' },
  { id: 5484, iso: 'qvh', name: 'Huamalíes-Dos de Mayo Huánuco Quechua' },
  { id: 5485, iso: 'qvi', name: 'Imbabura Highland Quichua' },
  { id: 5488, iso: 'qvm', name: 'Margos-Yarowilca-Lauricocha Quechua' },
  { id: 5490, iso: 'qvo', name: 'Napo Lowland Quechua' },
  { id: 5493, iso: 'qvw', name: 'Huaylla Wanca Quechua' },
  { id: 5498, iso: 'qwh', name: 'Huaylas Ancash Quechua' },
  { id: 5504, iso: 'qxh', name: 'Panao Huánuco Quechua' },
  { id: 5506, iso: 'qxn', name: 'Northern Conchucos Ancash Quechua' },
  { id: 5507, iso: 'qxo', name: 'Southern Conchucos Ancash Quechua' },
  { id: 5508, iso: 'qxp', name: 'Puno Quechua' },
  { id: 5509, iso: 'qxq', name: 'Qashqa\'i' },
  { id: 5510, iso: 'qxr', name: 'Cañar Highland Quichua' },
  { id: 5513, iso: 'qxu', name: 'Arequipa-La Unión Quechua' },
  { id: 5520, iso: 'rad', name: 'Rade' },
  { id: 5522, iso: 'rag', name: 'Logooli' },
  { id: 5523, iso: 'rah', name: 'Rabha' },
  { id: 5524, iso: 'rai', name: 'Ramoaaina' },
  { id: 5538, iso: 'raw', name: 'Rawang' },
  { id: 5542, iso: 'rbb', name: 'Rumai Palaung' },
  { id: 5544, iso: 'rbl', name: 'Miraya Bikol' },
  { id: 5546, iso: 'rcf', name: 'Réunion Creole French' },
  { id: 5553, iso: 'rej', name: 'Rejang' },
  { id: 5568, iso: 'rhg', name: 'Rohingya' },
  { id: 5570, iso: 'ria', name: 'Riang (India)' },
  { id: 5572, iso: 'rif', name: 'Tarifit' },
  { id: 5574, iso: 'rim', name: 'Nyaturu' },
  { id: 5581, iso: 'rjs', name: 'Rajbanshi' },
  { id: 5585, iso: 'rki', name: 'Rakhine' },
  { id: 5586, iso: 'rkm', name: 'Marka' },
  { id: 5587, iso: 'rkt', name: 'Rangpuri' },
  { id: 5591, iso: 'rmc', name: 'Carpathian Romani' },
  { id: 5594, iso: 'rmf', name: 'Kalo Finnish Romani' },
  { id: 5599, iso: 'rml', name: 'Baltic Romani' },
  { id: 5601, iso: 'rmn', name: 'Balkan Romani' },
  { id: 5602, iso: 'rmo', name: 'Sinte Romani' },
  { id: 5604, iso: 'rmq', name: 'Caló' },
  { id: 5606, iso: 'rmt', name: 'Domari' },
  { id: 5611, iso: 'rmy', name: 'Vlax Romani' },
  { id: 5612, iso: 'rmz', name: 'Marma' },
  { id: 5614, iso: 'rnd', name: 'Ruund' },
  { id: 5615, iso: 'rng', name: 'Ronga' },
  { id: 5621, iso: 'rob', name: 'Tae\'' },
  { id: 5626, iso: 'rog', name: 'Northern Roglai' },
  { id: 5627, iso: 'roh', name: 'Romansh' },
  { id: 5628, iso: 'rol', name: 'Romblomanon' },
  { id: 5639, iso: 'rro', name: 'Waima' },
  { id: 5641, iso: 'rsb', name: 'Romano-Serbian' },
  { id: 5647, iso: 'rtw', name: 'Rathawi' },
  { id: 5648, iso: 'rub', name: 'Gungu' },
  { id: 5649, iso: 'ruc', name: 'Ruuli' },
  { id: 5650, iso: 'rue', name: 'Rusyn' },
  { id: 5651, iso: 'ruf', name: 'Luguru' },
  { id: 5654, iso: 'rui', name: 'Rufiji' },
  { id: 5655, iso: 'ruk', name: 'Che' },
  { id: 5661, iso: 'rut', name: 'Rutul' },
  { id: 5668, iso: 'rwo', name: 'Rawa' },
  { id: 5669, iso: 'rwr', name: 'Marwari (India)' },
  { id: 5674, iso: 'ryu', name: 'Central Okinawan' },
  { id: 5676, iso: 'sab', name: 'Buglere' },
  { id: 5681, iso: 'sag', name: 'Sango' },
  { id: 5682, iso: 'sah', name: 'Yakut' },
  { id: 5686, iso: 'san', name: 'Sanskrit' },
  { id: 5688, iso: 'saq', name: 'Samburu' },
  { id: 5690, iso: 'sas', name: 'Sasak' },
  { id: 5691, iso: 'sat', name: 'Santali' },
  { id: 5693, iso: 'sav', name: 'Saafi-Saafi' },
  { id: 5696, iso: 'say', name: 'Saya' },
  { id: 5697, iso: 'saz', name: 'Saurashtra' },
  { id: 5698, iso: 'sba', name: 'Ngambay' },
  { id: 5701, iso: 'sbd', name: 'Southern Samo' },
  { id: 5708, iso: 'sbk', name: 'Safwa' },
  { id: 5709, iso: 'sbl', name: 'Botolan Sambal' },
  { id: 5722, iso: 'sby', name: 'Soli' },
  { id: 5725, iso: 'sce', name: 'Dongxiang' },
  { id: 5729, iso: 'sci', name: 'Sri Lankan Creole Malay' },
  { id: 5730, iso: 'sck', name: 'Sadri' },
  { id: 5731, iso: 'scl', name: 'Shina' },
  { id: 5732, iso: 'scn', name: 'Sicilian' },
  { id: 5733, iso: 'sco', name: 'Scots' },
  { id: 5741, iso: 'sda', name: 'Toraja-Sa\'dan' },
  { id: 5743, iso: 'sdc', name: 'Sassarese Sardinian' },
  { id: 5747, iso: 'sdh', name: 'Southern Kurdish' },
  { id: 5748, iso: 'sdj', name: 'Suundi' },
  { id: 5752, iso: 'sdn', name: 'Gallurese Sardinian' },
  { id: 5755, iso: 'sdr', name: 'Oraon Sadri' },
  { id: 5762, iso: 'seb', name: 'Shempire Senoufo' },
  { id: 5766, iso: 'sef', name: 'Cebaara Senoufo' },
  { id: 5768, iso: 'seh', name: 'Sena' },
  { id: 5775, iso: 'sep', name: 'Sìcìté Sénoufo' },
  { id: 5778, iso: 'ses', name: 'Koyraboro Senni Songhai' },
  { id: 5779, iso: 'set', name: 'Sentani' },
  { id: 5789, iso: 'sfw', name: 'Sehwi' },
  { id: 5792, iso: 'sgc', name: 'Kipsigis' },
  { id: 5793, iso: 'sgd', name: 'Surigaonon' },
  { id: 5798, iso: 'sgj', name: 'Surgujia' },
  { id: 5807, iso: 'sgw', name: 'Sebat Bet Gurage' },
  { id: 5815, iso: 'she', name: 'Sheko' },
  { id: 5818, iso: 'shi', name: 'Tachelhit' },
  { id: 5820, iso: 'shk', name: 'Shilluk' },
  { id: 5823, iso: 'shn', name: 'Shan' },
  { id: 5825, iso: 'shp', name: 'Shipibo-Conibo' },
  { id: 5827, iso: 'shr', name: 'Shi' },
  { id: 5830, iso: 'shu', name: 'Chadian Arabic' },
  { id: 5834, iso: 'shy', name: 'Tachawit' },
  { id: 5835, iso: 'shz', name: 'Syenara Senoufo' },
  { id: 5838, iso: 'sid', name: 'Sidamo' },
  { id: 5841, iso: 'sig', name: 'Paasaal' },
  { id: 5846, iso: 'sil', name: 'Tumulung Sisaala' },
  { id: 5849, iso: 'sip', name: 'Sikkimese' },
  { id: 5866, iso: 'sjm', name: 'Mapun' },
  { id: 5869, iso: 'sjp', name: 'Surjapuri' },
  { id: 5881, iso: 'skg', name: 'Sakalava Malagasy' },
  { id: 5883, iso: 'ski', name: 'Sika' },
  { id: 5891, iso: 'skr', name: 'Seraiki' },
  { id: 5901, iso: 'sld', name: 'Sissala' },
  { id: 5910, iso: 'slm', name: 'Pangutaran Sama' },
  { id: 5912, iso: 'slp', name: 'Lamaholot' },
  { id: 5921, iso: 'sly', name: 'Selayar' },
  { id: 5927, iso: 'sme', name: 'Northern Sami' },
  { id: 5933, iso: 'sml', name: 'Central Sama' },
  { id: 5936, iso: 'smo', name: 'Samoan' },
  { id: 5941, iso: 'smt', name: 'Simte' },
  { id: 5944, iso: 'smw', name: 'Sumbawa' },
  { id: 5948, iso: 'sna', name: 'Shona' },
  { id: 5950, iso: 'snc', name: 'Sinaugoro' },
  { id: 5953, iso: 'snf', name: 'Noon' },
  { id: 5954, iso: 'sng', name: 'Sanga (Democratic Republic of Congo)' },
  { id: 5958, iso: 'snk', name: 'Soninke' },
  { id: 5959, iso: 'snl', name: 'Sangil' },
  { id: 5963, iso: 'snp', name: 'Siane' },
  { id: 5969, iso: 'snw', name: 'Selee' },
  { id: 5973, iso: 'soa', name: 'Thai Song' },
  { id: 5986, iso: 'sop', name: 'Songe' },
  { id: 5990, iso: 'sot', name: 'Southern Sotho' },
  { id: 5991, iso: 'sou', name: 'Southern Thai' },
  { id: 6009, iso: 'spp', name: 'Supyire Senoufo' },
  { id: 6015, iso: 'spv', name: 'Sambalpuri' },
  { id: 6017, iso: 'spy', name: 'Sabaot' },
  { id: 6031, iso: 'srb', name: 'Sora' },
  { id: 6032, iso: 'src', name: 'Logudorese Sardinian' },
  { id: 6041, iso: 'srm', name: 'Saramaccan' },
  { id: 6042, iso: 'srn', name: 'Sranan Tongo' },
  { id: 6043, iso: 'sro', name: 'Campidanese Sardinian' },
  { id: 6046, iso: 'srr', name: 'Serer' },
  { id: 6050, iso: 'srv', name: 'Southern Sorsoganon' },
  { id: 6052, iso: 'srx', name: 'Sirmauri' },
  { id: 6055, iso: 'ssb', name: 'Southern Sama' },
  { id: 6056, iso: 'ssc', name: 'Suba-Simbiti' },
  { id: 6058, iso: 'sse', name: 'Balangingi' },
  { id: 6065, iso: 'ssl', name: 'Western Sisaala' },
  { id: 6072, iso: 'sss', name: 'Sô' },
  { id: 6073, iso: 'sst', name: 'Sinasina' },
  { id: 6076, iso: 'ssw', name: 'Swati' },
  { id: 6078, iso: 'ssy', name: 'Saho' },
  { id: 6081, iso: 'stb', name: 'Northern Subanen' },
  { id: 6088, iso: 'stj', name: 'Matya Samo' },
  { id: 6094, iso: 'stp', name: 'Southeastern Tepehuan' },
  { id: 6100, iso: 'stv', name: 'Silt\'e' },
  { id: 6102, iso: 'sty', name: 'Siberian Tatar' },
  { id: 6105, iso: 'suc', name: 'Western Subanon' },
  { id: 6109, iso: 'suj', name: 'Shubi' },
  { id: 6110, iso: 'suk', name: 'Sukuma' },
  { id: 6112, iso: 'suq', name: 'Suri' },
  { id: 6113, iso: 'sur', name: 'Mwaghavul' },
  { id: 6114, iso: 'sus', name: 'Susu' },
  { id: 6117, iso: 'suw', name: 'Sumbwa' },
  { id: 6123, iso: 'svc', name: 'Vincentian Creole English' },
  { id: 6127, iso: 'svr', name: 'Savara' },
  { id: 6131, iso: 'swb', name: 'Maore Comorian' },
  { id: 6135, iso: 'swg', name: 'Swabian' },
  { id: 6137, iso: 'swi', name: 'Sui' },
  { id: 6139, iso: 'swk', name: 'Malawi Sena' },
  { id: 6150, iso: 'swv', name: 'Shekhawati' },
  { id: 6154, iso: 'sxb', name: 'Suba' },
  { id: 6161, iso: 'sxn', name: 'Sangir' },
  { id: 6165, iso: 'sxu', name: 'Upper Saxon' },
  { id: 6166, iso: 'sxw', name: 'Saxwe Gbe' },
  { id: 6168, iso: 'syb', name: 'Central Subanen' },
  { id: 6172, iso: 'syl', name: 'Sylheti' },
  { id: 6192, iso: 'tab', name: 'Tabassaran' },
  { id: 6193, iso: 'tac', name: 'Lowland Tarahumara' },
  { id: 6198, iso: 'tah', name: 'Tahitian' },
  { id: 6199, iso: 'taj', name: 'Eastern Tamang' },
  { id: 6203, iso: 'tan', name: 'Tangale' },
  { id: 6205, iso: 'tap', name: 'Taabwa' },
  { id: 6206, iso: 'taq', name: 'Tamasheq' },
  { id: 6214, iso: 'tay', name: 'Atayal' },
  { id: 6218, iso: 'tbc', name: 'Takia' },
  { id: 6226, iso: 'tbk', name: 'Calamian Tagbanwa' },
  { id: 6227, iso: 'tbl', name: 'Tboli' },
  { id: 6230, iso: 'tbo', name: 'Tawala' },
  { id: 6234, iso: 'tbt', name: 'Tembo (Kitembo)' },
  { id: 6237, iso: 'tbw', name: 'Tagbanwa' },
  { id: 6239, iso: 'tby', name: 'Tabaru' },
  { id: 6240, iso: 'tbz', name: 'Ditammari' },
  { id: 6241, iso: 'tca', name: 'Ticuna' },
  { id: 6243, iso: 'tcc', name: 'Datooga' },
  { id: 6246, iso: 'tcf', name: 'Malinaltepec Me\'phaa' },
  { id: 6262, iso: 'tcy', name: 'Tulu' },
  { id: 6263, iso: 'tcz', name: 'Thado Chin' },
  { id: 6265, iso: 'tdb', name: 'Panchpargania' },
  { id: 6267, iso: 'tdd', name: 'Tai Nüa' },
  { id: 6270, iso: 'tdg', name: 'Western Tamang' },
  { id: 6271, iso: 'tdh', name: 'Thulung' },
  { id: 6281, iso: 'tdt', name: 'Tetun Dili' },
  { id: 6284, iso: 'tdx', name: 'Tandroy-Mahafaly Malagasy' },
  { id: 6286, iso: 'tea', name: 'Temiar' },
  { id: 6288, iso: 'tec', name: 'Terik' },
  { id: 6289, iso: 'ted', name: 'Tepo Krumen' },
  { id: 6295, iso: 'tek', name: 'Ibali Teke' },
  { id: 6297, iso: 'tem', name: 'Timne' },
  { id: 6299, iso: 'teo', name: 'Teso' },
  { id: 6304, iso: 'tet', name: 'Tetum' },
  { id: 6308, iso: 'tex', name: 'Tennet' },
  { id: 6315, iso: 'tga', name: 'Sagalla' },
  { id: 6321, iso: 'tgh', name: 'Tobagonian Creole English' },
  { id: 6326, iso: 'tgn', name: 'Tandaganon' },
  { id: 6335, iso: 'tgw', name: 'Tagwana Senoufo' },
  { id: 6342, iso: 'the', name: 'Chitwania Tharu' },
  { id: 6346, iso: 'thk', name: 'Tharaka' },
  { id: 6347, iso: 'thl', name: 'Dangaura Tharu' },
  { id: 6351, iso: 'thq', name: 'Kochila Tharu' },
  { id: 6352, iso: 'thr', name: 'Rana Tharu' },
  { id: 6360, iso: 'thz', name: 'Tayart Tamajeq' },
  { id: 6362, iso: 'tic', name: 'Tira' },
  { id: 6365, iso: 'tig', name: 'Tigre' },
  { id: 6369, iso: 'tik', name: 'Tikar' },
  { id: 6371, iso: 'tim', name: 'Timbe' },
  { id: 6380, iso: 'tiv', name: 'Tiv' },
  { id: 6383, iso: 'tiy', name: 'Tiruray' },
  { id: 6388, iso: 'tjl', name: 'Tai Laing' },
  { id: 6398, iso: 'tke', name: 'Takwane' },
  { id: 6400, iso: 'tkg', name: 'Tesaka Malagasy' },
  { id: 6405, iso: 'tkq', name: 'Tee' },
  { id: 6407, iso: 'tks', name: 'Takestani' },
  { id: 6408, iso: 'tkt', name: 'Kathoriya Tharu' },
  { id: 6415, iso: 'tlb', name: 'Tobelo' },
  { id: 6422, iso: 'tlj', name: 'Talinga-Bwisi' },
  { id: 6424, iso: 'tll', name: 'Tetela' },
  { id: 6436, iso: 'tly', name: 'Talysh' },
  { id: 6471, iso: 'tnl', name: 'Lenakel' },
  { id: 6479, iso: 'tnt', name: 'Tontemboan' },
  { id: 6487, iso: 'toc', name: 'Coyutla Totonac' },
  { id: 6488, iso: 'tod', name: 'Toma' },
  { id: 6491, iso: 'tog', name: 'Tonga (Nyasa)' },
  { id: 6492, iso: 'toh', name: 'Gitonga' },
  { id: 6494, iso: 'toj', name: 'Tojolabal' },
  { id: 6497, iso: 'ton', name: 'Tonga (Tonga Islands)' },
  { id: 6499, iso: 'top', name: 'Papantla Totonac' },
  { id: 6500, iso: 'toq', name: 'Toposa' },
  { id: 6502, iso: 'tos', name: 'Highland Totonac' },
  { id: 6514, iso: 'tpi', name: 'Tok Pisin' },
  { id: 6518, iso: 'tpm', name: 'Tampulma' },
  { id: 6535, iso: 'tqo', name: 'Toaripi' },
  { id: 6544, iso: 'trc', name: 'Copala Triqui' },
  { id: 6547, iso: 'trf', name: 'Trinidadian Creole English' },
  { id: 6556, iso: 'trp', name: 'Kok Borok' },
  { id: 6562, iso: 'trv', name: 'Taroko' },
  { id: 6569, iso: 'tsc', name: 'Tswa' },
  { id: 6572, iso: 'tsf', name: 'Southwestern Tamang' },
  { id: 6573, iso: 'tsg', name: 'Tausug' },
  { id: 6576, iso: 'tsj', name: 'Tshangla' },
  { id: 6580, iso: 'tsn', name: 'Tswana' },
  { id: 6589, iso: 'tsw', name: 'Tsishingini' },
  { id: 6592, iso: 'tsz', name: 'Purepecha' },
  { id: 6602, iso: 'ttj', name: 'Tooro' },
  { id: 6609, iso: 'ttq', name: 'Tawallammat Tamajaq' },
  { id: 6610, iso: 'ttr', name: 'Tera' },
  { id: 6611, iso: 'tts', name: 'Northeastern Thai' },
  { id: 6626, iso: 'tui', name: 'Tupuri' },
  { id: 6630, iso: 'tum', name: 'Tumbuka' },
  { id: 6637, iso: 'tuv', name: 'Turkana' },
  { id: 6639, iso: 'tuy', name: 'Tugen' },
  { id: 6642, iso: 'tvd', name: 'Tsuvadi' },
  { id: 6645, iso: 'tvl', name: 'Tuvalu' },
  { id: 6647, iso: 'tvn', name: 'Tavoyan' },
  { id: 6649, iso: 'tvs', name: 'Taveta' },
  { id: 6651, iso: 'tvu', name: 'Tunen' },
  { id: 6661, iso: 'twh', name: 'Tai Dón' },
  { id: 6673, iso: 'twx', name: 'Tewe' },
  { id: 6674, iso: 'twy', name: 'Tawoyan' },
  { id: 6691, iso: 'txy', name: 'Tanosy Malagasy' },
  { id: 6704, iso: 'tyv', name: 'Tuvinian' },
  { id: 6706, iso: 'tyz', name: 'Tày' },
  { id: 6708, iso: 'tzh', name: 'Tzeltal' },
  { id: 6709, iso: 'tzj', name: 'Tz\'utujil' },
  { id: 6711, iso: 'tzm', name: 'Central Atlas Tamazight' },
  { id: 6713, iso: 'tzo', name: 'Tzotzil' },
  { id: 6722, iso: 'ubu', name: 'Umbu-Ungu' },
  { id: 6729, iso: 'udl', name: 'Wuzlam' },
  { id: 6730, iso: 'udm', name: 'Udmurt' },
  { id: 6731, iso: 'udu', name: 'Uduk' },
  { id: 6755, iso: 'ukw', name: 'Ukwuani-Aboh-Ndoni' },
  { id: 6770, iso: 'umb', name: 'Umbundu' },
  { id: 6775, iso: 'umm', name: 'Umon' },
  { id: 6789, iso: 'unr', name: 'Mundari' },
  { id: 6791, iso: 'unx', name: 'Munda' },
  { id: 6803, iso: 'urh', name: 'Urhobo' },
  { id: 6821, iso: 'usi', name: 'Usui' },
  { id: 6845, iso: 'vag', name: 'Vagla' },
  { id: 6846, iso: 'vah', name: 'Varhadi-Nagpuri' },
  { id: 6847, iso: 'vai', name: 'Vai' },
  { id: 6853, iso: 'vap', name: 'Vaiphei' },
  { id: 6855, iso: 'vas', name: 'Vasavi' },
  { id: 6857, iso: 'vav', name: 'Varli' },
  { id: 6861, iso: 'vec', name: 'Venetian' },
  { id: 6865, iso: 'ven', name: 'Venda' },
  { id: 6868, iso: 'ver', name: 'Mom Jango' },
  { id: 6889, iso: 'vkt', name: 'Tenggarong Kutai Malay' },
  { id: 6892, iso: 'vls', name: 'Vlaams' },
  { id: 6898, iso: 'vmf', name: 'Mainfränkisch' },
  { id: 6903, iso: 'vmk', name: 'Makhuwa-Shirima' },
  { id: 6912, iso: 'vmw', name: 'Makhuwa' },
  { id: 6931, iso: 'vun', name: 'Vunjo' },
  { id: 6932, iso: 'vut', name: 'Vute' },
  { id: 6944, iso: 'wal', name: 'Wolaytta' },
  { id: 6964, iso: 'wbi', name: 'Vwanji' },
  { id: 6968, iso: 'wbm', name: 'Wa' },
  { id: 6970, iso: 'wbq', name: 'Waddar' },
  { id: 6971, iso: 'wbr', name: 'Wagdi' },
  { id: 6976, iso: 'wci', name: 'Waci Gbe' },
  { id: 6984, iso: 'wec', name: 'Wè Western' },
  { id: 7001, iso: 'wgi', name: 'Wahgi' },
  { id: 7022, iso: 'wiu', name: 'Wiru' },
  { id: 7042, iso: 'wln', name: 'Walloon' },
  { id: 7047, iso: 'wlv', name: 'Wichí Lhamtés Vejoz' },
  { id: 7049, iso: 'wlx', name: 'Wali (Ghana)' },
  { id: 7055, iso: 'wme', name: 'Wambule' },
  { id: 7063, iso: 'wmw', name: 'Mwani' },
  { id: 7070, iso: 'wni', name: 'Ndzwani Comorian' },
  { id: 7080, iso: 'wob', name: 'Wè Northern' },
  { id: 7084, iso: 'wof', name: 'Gambian Wolof' },
  { id: 7088, iso: 'wol', name: 'Wolof' },
  { id: 7115, iso: 'wry', name: 'Merwari' },
  { id: 7119, iso: 'wsk', name: 'Waskia' },
  { id: 7121, iso: 'wss', name: 'Wasa' },
  { id: 7126, iso: 'wti', name: 'Berta' },
  { id: 7128, iso: 'wtm', name: 'Mewati' },
  { id: 7143, iso: 'wwa', name: 'Waama' },
  { id: 7148, iso: 'wxa', name: 'Waxianghua' },
  { id: 7166, iso: 'xan', name: 'Xamtanga' },
  { id: 7189, iso: 'xbr', name: 'Kambera' },
  { id: 7212, iso: 'xdy', name: 'Malayic Dayak' },
  { id: 7214, iso: 'xed', name: 'Hdi' },
  { id: 7250, iso: 'xis', name: 'Kisan' },
  { id: 7295, iso: 'xmc', name: 'Makhuwa-Marrevone' },
  { id: 7298, iso: 'xmf', name: 'Mingrelian' },
  { id: 7304, iso: 'xmm', name: 'Manado Malay' },
  { id: 7313, iso: 'xmv', name: 'Antankarana Malagasy' },
  { id: 7314, iso: 'xmw', name: 'Tsimihety Malagasy' },
  { id: 7324, iso: 'xnn', name: 'Northern Kankanay' },
  { id: 7326, iso: 'xnr', name: 'Kangri' },
  { id: 7331, iso: 'xnz', name: 'Kenzi' },
  { id: 7334, iso: 'xog', name: 'Soga' },
  { id: 7338, iso: 'xon', name: 'Konkomba' },
  { id: 7345, iso: 'xpe', name: 'Liberia Kpelle' },
  { id: 7363, iso: 'xrb', name: 'Eastern Karaboro' },
  { id: 7376, iso: 'xsb', name: 'Sambal' },
  { id: 7384, iso: 'xsm', name: 'Kasem' },
  { id: 7388, iso: 'xsq', name: 'Makhuwa-Saka' },
  { id: 7389, iso: 'xsr', name: 'Sherpa' },
  { id: 7405, iso: 'xtn', name: 'Northern Tlaxiaco Mixtec' },
  { id: 7418, iso: 'xub', name: 'Betta Kurumba' },
  { id: 7425, iso: 'xuo', name: 'Kuo' },
  { id: 7467, iso: 'yaf', name: 'Yaka (Democratic Republic of Congo)' },
  { id: 7473, iso: 'yal', name: 'Yalunka' },
  { id: 7474, iso: 'yam', name: 'Yamba' },
  { id: 7476, iso: 'yao', name: 'Yao' },
  { id: 7478, iso: 'yaq', name: 'Yaqui' },
  { id: 7480, iso: 'yas', name: 'Nugunu (Cameroon)' },
  { id: 7486, iso: 'yay', name: 'Agwagwune' },
  { id: 7487, iso: 'yaz', name: 'Lokaa' },
  { id: 7488, iso: 'yba', name: 'Yala' },
  { id: 7489, iso: 'ybb', name: 'Yemba' },
  { id: 7495, iso: 'ybl', name: 'Yukuben' },
  { id: 7502, iso: 'ycl', name: 'Lolopo' },
  { id: 7506, iso: 'ydd', name: 'Eastern Yiddish' },
  { id: 7511, iso: 'yea', name: 'Ravula' },
  { id: 7517, iso: 'yer', name: 'Tarok' },
  { id: 7520, iso: 'yeu', name: 'Yerukula' },
  { id: 7527, iso: 'ygp', name: 'Gepo' },
  { id: 7528, iso: 'ygr', name: 'Yagaria' },
  { id: 7531, iso: 'ygw', name: 'Yagwoia' },
  { id: 7533, iso: 'yhd', name: 'Judeo-Iraqi Arabic' },
  { id: 7538, iso: 'yig', name: 'Wusa Nasu' },
  { id: 7544, iso: 'yim', name: 'Yimchungru Naga' },
  { id: 7552, iso: 'yiv', name: 'Northern Nisu' },
  { id: 7553, iso: 'yix', name: 'Axi Yi' },
  { id: 7555, iso: 'yka', name: 'Yakan' },
  { id: 7566, iso: 'yky', name: 'Yakoma' },
  { id: 7571, iso: 'yli', name: 'Angguruk Yali' },
  { id: 7588, iso: 'ymm', name: 'Maay' },
  { id: 7608, iso: 'yns', name: 'Yansi' },
  { id: 7615, iso: 'yom', name: 'Yombe' },
  { id: 7632, iso: 'yre', name: 'Yaouré' },
  { id: 7645, iso: 'ysn', name: 'Sani' },
  { id: 7647, iso: 'ysp', name: 'Southern Lolopo' },
  { id: 7656, iso: 'yua', name: 'Yucateco' },
  { id: 7677, iso: 'yva', name: 'Yawa' },
  { id: 7683, iso: 'ywq', name: 'Wuding-Luquan Yi' },
  { id: 7685, iso: 'ywt', name: 'Xishanba Lalo' },
  { id: 7686, iso: 'ywu', name: 'Wumeng Nasu' },
  { id: 7700, iso: 'zab', name: 'San Juan Guelavía Zapotec' },
  { id: 7707, iso: 'zai', name: 'Isthmus Zapotec' },
  { id: 7709, iso: 'zak', name: 'Zanaki' },
  { id: 7715, iso: 'zar', name: 'Rincón Zapotec' },
  { id: 7720, iso: 'zaw', name: 'Mitla Zapotec' },
  { id: 7722, iso: 'zay', name: 'Zayse-Zergulla' },
  { id: 7730, iso: 'zch', name: 'Central Hongshuihe Zhuang' },
  { id: 7731, iso: 'zdj', name: 'Ngazidja Comorian' },
  { id: 7732, iso: 'zea', name: 'Zeeuws' },
  { id: 7734, iso: 'zeh', name: 'Eastern Hongshuihe Zhuang' },
  { id: 7736, iso: 'zga', name: 'Kinga' },
  { id: 7737, iso: 'zgb', name: 'Guibei Zhuang' },
  { id: 7739, iso: 'zgm', name: 'Minz Zhuang' },
  { id: 7740, iso: 'zgn', name: 'Guibian Zhuang' },
  { id: 7744, iso: 'zhd', name: 'Dai Zhuang' },
  { id: 7746, iso: 'zhn', name: 'Nong Zhuang' },
  { id: 7753, iso: 'zim', name: 'Mesme' },
  { id: 7754, iso: 'zin', name: 'Zinza' },
  { id: 7756, iso: 'ziw', name: 'Zigula' },
  { id: 7772, iso: 'zlj', name: 'Liujiang Zhuang' },
  { id: 7773, iso: 'zlm', name: 'Malay (individual language)' },
  { id: 7775, iso: 'zlq', name: 'Liuqian Zhuang' },
  { id: 7777, iso: 'zmb', name: 'Zimba' },
  { id: 7791, iso: 'zmp', name: 'Mpuono' },
  { id: 7801, iso: 'zmz', name: 'Mbandja' },
  { id: 7803, iso: 'zne', name: 'Zande (individual language)' },
  { id: 7809, iso: 'zom', name: 'Zou' },
  { id: 7819, iso: 'zpf', name: 'San Pedro Quiatoni Zapotec' },
  { id: 7828, iso: 'zpo', name: 'Amatlán Zapotec' },
  { id: 7840, iso: 'zqe', name: 'Qiubei Zhuang' },
  { id: 7851, iso: 'zsr', name: 'Southern Rincon Zapotec' },
  { id: 7873, iso: 'zyb', name: 'Yongbei Zhuang' },
  { id: 7874, iso: 'zyg', name: 'Yang Zhuang' },
  { id: 7875, iso: 'zyj', name: 'Youjiang Zhuang' },
  { id: 7876, iso: 'zyn', name: 'Yongnan Zhuang' },
  { id: 7877, iso: 'zyp', name: 'Zyphe Chin' },
  { id: 7879, iso: 'zzj', name: 'Zuojiang Zhuang' },
  { id: 7884, iso: 'aae', name: 'Arbëreshë Albanian' },
  { id: 7893, iso: 'aao', name: 'Algerian Saharan Arabic' },
  { id: 7902, iso: 'aaz', name: 'Amarasi' },
  { id: 7903, iso: 'aba', name: 'Abé' },
  { id: 7911, iso: 'abi', name: 'Abidji' },
  { id: 7913, iso: 'abk', name: 'Abkhazian' },
  { id: 7914, iso: 'abl', name: 'Lampung Nyo' },
  { id: 7919, iso: 'abq', name: 'Abaza' },
  { id: 7920, iso: 'abr', name: 'Abron' },
  { id: 7921, iso: 'abs', name: 'Ambonese Malay' },
  { id: 7922, iso: 'abt', name: 'Ambulas' },
  { id: 7924, iso: 'abv', name: 'Baharna Arabic' },
  { id: 7926, iso: 'abx', name: 'Inabaknon' },
  { id: 7931, iso: 'acd', name: 'Gikyode' },
  { id: 7932, iso: 'ace', name: 'Achinese' },
  { id: 7933, iso: 'acf', name: 'Saint Lucian Creole French' },
  { id: 7934, iso: 'ach', name: 'Acoli' },
  { id: 7941, iso: 'acq', name: 'Ta\'izzi-Adeni Arabic' },
  { id: 7942, iso: 'acr', name: 'Achi' },
  { id: 7947, iso: 'acw', name: 'Hijazi Arabic' },
  { id: 7948, iso: 'acx', name: 'Omani Arabic' },
  { id: 7951, iso: 'ada', name: 'Adangme' },
  { id: 7954, iso: 'ade', name: 'Adele' },
  { id: 7957, iso: 'adh', name: 'Adhola' },
  { id: 7958, iso: 'adi', name: 'Adi' },
  { id: 7959, iso: 'adj', name: 'Adioukrou' },
  { id: 7960, iso: 'adl', name: 'Galo' },
  { id: 7970, iso: 'adx', name: 'Amdo Tibetan' },
  { id: 7971, iso: 'ady', name: 'Adyghe' },
  { id: 7972, iso: 'adz', name: 'Adzera' },
  { id: 7975, iso: 'aec', name: 'Saidi Arabic' },
  { id: 7989, iso: 'afb', name: 'Gulf Arabic' },
  { id: 8002, iso: 'afu', name: 'Awutu' },
  { id: 8007, iso: 'agd', name: 'Agarabi' },
  { id: 8008, iso: 'age', name: 'Angal' },
  { id: 8017, iso: 'agn', name: 'Agutaynen' },
  { id: 8019, iso: 'agq', name: 'Aghem' },
  { id: 8020, iso: 'agr', name: 'Aguaruna' },
  { id: 8023, iso: 'agu', name: 'Aguacateco' },
  { id: 8029, iso: 'aha', name: 'Ahanta' },
  { id: 8034, iso: 'ahk', name: 'Akha' },
  { id: 8040, iso: 'ahr', name: 'Ahirani' },
  { id: 8059, iso: 'aiq', name: 'Aimaq' },
  { id: 8063, iso: 'aiw', name: 'Aari' },
  { id: 8067, iso: 'ajg', name: 'Aja (Benin)' },
  { id: 8072, iso: 'aju', name: 'Judeo-Moroccan Arabic' },
  { id: 8074, iso: 'ajz', name: 'Amri Karbi' },
  { id: 8075, iso: 'aka', name: 'Akan' },
  { id: 8076, iso: 'akb', name: 'Batak Angkola' }]
export { allLanguages }
